import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../layouts/header";
import DescirptionHome from "../components/landing/description-home";
import TryApp from "../components/landing/try-app";
import Plans from "../components/landing/plans";
import FeaturesSax from "../components/landing/feature-sax";
import Footer from "../components/footer";
import { homePlans } from "../actions/plans"
import  ModalDialogComponent from '../components/dialog/dialog-component'
import  ModalDialogComponentNews from '../components/dialog/dialog-component-news'
import { useTranslation } from "react-i18next";
import { errors } from "../config/errors";

function transformToAssocArray( prmstr ) {
    var params = {};
    var prmarr = prmstr.split("&");
    for ( var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
    }
    return params;
}
function Landing(props) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [modalMessages, setModalMessages] = useState(false);
    const [modalMessagesNotification, setModalMessagesNotification] = useState(false);
    const [modalMessageActiveAccount, setModalMessageActiveAccount] = useState(false);
    const [modalMessageExpiredPlan, setModalMessageExpiredPlan] = useState(false);

    const [message, setMessage] = useState(false);

    useEffect(() => {
        !localStorage.getItem('saxToken') && setModalMessagesNotification(false)
        let  params = window.location.search.substr(1);
        params =  params ? transformToAssocArray(params) : {};

        if(params['expirate']) {
            setModalMessages(true);
        };

        if ( params['active_user'] ) {
            setModalMessageActiveAccount(true);
        };

        if(params['expiratedPlan']) {
            setModalMessageExpiredPlan(true);
        };

        homePlans()
            .then(response => {

                setPlans(response.response)

            })
            .catch(error => {
                errors.addErroLog(
                    {
                      data: {
                        url: window.location,
                        error: error.toString(),
                        message: error.message,
                        file: error.file,
                        line: error.line
                      }
                    }).then();
                console.log(error);
            });
    }, []);

    return (
        <>
        <ModalDialogComponent
        view = {modalMessages}
        tittle ={t(`modals.sessionExpired.title`)}
        message={t(`modals.sessionExpired.message`)}
        buttons={[
            {value:'Aceptar',onclick:()=>{setModalMessages(false); }}
        ]}
        />

        <ModalDialogComponentNews
        view = {modalMessagesNotification}
        tittle ={t(`modals.newSettings.title`)}
        messageAlert={t(`modals.newSettings.messageAlert`)}
        message={t(`modals.newSettings.message`)}
        buttons={[
            {value:'Aceptar',onclick:()=>{setModalMessagesNotification(false)}}
        ]}
        />

        <ModalDialogComponentNews
        view= { modalMessageActiveAccount }
        tittle={t(`modals.activeSession.title`)}
        messageAlert ={t(`modals.activeSession.messageAlert`)}
        message= {t(`modals.activeSession.message`)}
        buttons= { [
            { value: t(`modals.Understood`), onclick: ()=>{ setModalMessageActiveAccount( false ) } }
        ]}
        />

        <ModalDialogComponent
        view = {modalMessageExpiredPlan}
        tittle ={t(`modals.licenseExpired.title`)}
        message={t(`modals.licenseExpired.message`)}
        buttons={[
            {value:t(`modals.contact`),onclick:()=>{
                setModalMessageExpiredPlan( false );
                navigate('/contacto')
            }},
            {value:t(`modals.close`),onclick:()=>{ setModalMessageExpiredPlan( false ); }}
        ]}
        />


            <Header {...props} />
            <DescirptionHome />
            <TryApp />
            <FeaturesSax />
            <Plans plans={plans} />
            <Footer />
        </>
    )
}

export default Landing;