import { SET_TOKEN } from "../actions/users";

const initialState = {
  token: null
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload
      };
    }
    default:
      return state;
  }
};
