import { Grid } from "@mui/material";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


function EmulateCase() {
    return (
        <>
            <Paper
                elevation={3}
                style={{
                    padding: 30,
                    height: 'calc(100vh - 180px)'
                }}
            >
                <Grid container
                    spacing={5}
                    direction="row"
                    justifyContent="space-around"
                    alignItems="stretch"
                >
                    <Grid item xs={6}>
                        <Typography variant="h6" style={{ marginBottom: 15 }}>
                            Entrada (linearDescription)*
                        </Typography>
                        <TextField
                            id="outlined-multiline-static"
                            // label="Entrada (linearDescription)*"
                            multiline
                            rows={12}
                            // defaultValue="Default Value"
                            fullWidth
                            placeholder="{ 
                                &quot;caseName&quot;: &quot;Case Name&quot;,
                                &quot;source&quot;: &quot;PF&quot;,
                                &quot;caseDescription&quot;: &quot;Case description&quot;,
                                &quot;user&quot;: &quot;50000&quot;,
                                &quot;executionId&quot;: &quot;1111111111&quot;,
                                &quot;deep&quot;: 1000000,
                                &quot;levels&quot;: 5,
                                &quot;pes&quot;: [...],
                                &quot;sensitivities&quot;: [...]
                               }"
                        />

                    </Grid>

                    <Grid
                        item
                        xs={6}

                    >
                        <Typography variant="h6" style={{ marginBottom: 15 }}>
                            Salida (caseResult)*
                        </Typography>
                        <TextField
                            id="outlined-multiline-static"
                            // label="Salida (caseResult)*"
                            multiline
                            rows={12}
                            // defaultValue="Default Value"
                            fullWidth
                            style={{
                                marginBottom: 15
                            }}
                            placeholder="{
                                &quot;caseName&quot;: &quot;Case Name&quot;,
                                &quot;caseDescription&quot;: &quot;Case Description&quot;,
                                &quot;user&quot;: &quot;50000&quot;,
                                &quot;layers&quot;: [...],
                                &quot;executionTime&quot;: 6.234375
                               }"
                        />
                    </Grid>
                </Grid>

                <Grid>
                    <Grid item xs={6} style={{ display: 'flex' }}>
                        <Button variant="outlined" >
                            Cargar caso de estudio
                        </Button>
                    </Grid>

                </Grid>
            </Paper>

        </>
    );
}

export default EmulateCase;