import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Link } from "react-router-dom";

import logoSax from "../../assets/logo-sax-trans.png";
import UserProfile from "./user-profile";

export default function HeaderUser() {
    return (
        <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} style={{ backgroundColor: "white", color: "black", boxShadow: "none", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} >
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ display: 'flex', }}>
                    <Link to={`/`} style={{ display: 'flex' }}>
                        <img src={logoSax} alt="" style={{ width: 100 }} />
                    </Link>
                </Typography>
                <div style={{ display: "flex" }}>
                    <Typography variant="subtitle2" gutterBottom sx={{ flexGrow: 1 }} style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 0 }}>
                        <Link to={`/`} style={{ textDecoration: "none", marginRight: 10 }}>
                            ir al inicio
                        </Link>
                    </Typography>

                    <UserProfile />
                </div>
            </Toolbar>
        </AppBar>
    );
}