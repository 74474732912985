
function Title(props) {
    return (
        <div
            style={{
                background: 'linear-gradient(90deg,#3f008e,#7220db 39.39%,#ff6a13 100.59%)',
                color: '#fff',
                padding: '25px 0',
                margin: '0 0 40px 0',
            }}
        >
            <h1
                style={{
                    margin: 0,
                    fontSize: '26px',
                    fontWeight: 500,
                    lineHeight: 1.1
                }}
            >
                {props.title}
            </h1>
            {props.subtitle && (
                <p
                    style={{
                        fontSize: '18px',
                        margin: '10px 0 0 0',
                    }}
                >
                    {props.subtitle}
                </p>
            )}
        </div>
    );
}

export default Title;