import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';


export default function ModalTermsAndConditions() {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <b
                onClick={handleClickOpen('paper')}
                style={{
                    marginLeft: 5,
                    marginRight: 5,
                    cursor: 'pointer'
                }}
            >
                {t(`terms&conditions.title`)}
            </b>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth={'lg'}
            >
                <DialogTitle id="scroll-dialog-title">{t(`terms&conditions.title`)}
                </DialogTitle>
                <DialogContent dividers >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <div>
                        <div class="modal-body">
    <div id="box" class="form-control">

      <p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.descriptionInitial`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.descriptionInitial1`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.descriptionInitial2`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.descriptionInitial3`)}</p>
<p style={{textAlign: "justify"}}><strong>1. {t(`terms&conditions.subtitle1.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle1.subtitle1`)}</strong> {t(`terms&conditions.subtitle1.description1`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle1.subtitle2`)}</strong> {t(`terms&conditions.subtitle1.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle1.subtitle3`)}</strong> {t(`terms&conditions.subtitle1.description3`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle1.subtitle4`)}</strong> {t(`terms&conditions.subtitle1.description4`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle1.subtitle5.1`)} </strong>{t(`terms&conditions.subtitle1.subtitle5.2`)}<strong> {t(`terms&conditions.subtitle1.subtitle5.3`)}</strong> {t(`terms&conditions.subtitle1.description5`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle1.subtitle6`)}</strong> {t(`terms&conditions.subtitle1.description6`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle1.subtitle7`)}</strong> {t(`terms&conditions.subtitle1.description7`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle1.subtitle8`)}</strong> {t(`terms&conditions.subtitle1.description8`)}</p>
<p style={{textAlign: "justify"}}><strong>2. {t(`terms&conditions.subtitle2`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>3. {t(`terms&conditions.subtitle3.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle3.description1`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle3.description2`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle3.description3`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle3.description4`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle3.description5`)}</p>
<p style={{textAlign: "justify"}}><strong>4. {t(`terms&conditions.subtitle4.subTitle`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>4.1</strong> {t(`terms&conditions.subtitle4.description1`)}</p>
<p style={{textAlign: "justify"}}><strong>4.2</strong> {t(`terms&conditions.subtitle4.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>4.3</strong> {t(`terms&conditions.subtitle4.description3`)}</p>
<p style={{textAlign: "justify"}}><strong>4.4</strong> {t(`terms&conditions.subtitle4.description4`)}</p>
<p style={{textAlign: "justify"}}><strong>5. {t(`terms&conditions.subtitle5`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.description5`)}</p>
<p style={{textAlign: "justify"}}><strong>6. {t(`terms&conditions.subtitle6.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>6.1</strong> {t(`terms&conditions.subtitle6.description1.1`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle6.description1.2`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle6.description1.3`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle6.description1.4`)}</p>
<p style={{textAlign: "justify"}}><strong>6.2</strong> {t(`terms&conditions.subtitle6.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>6.3</strong> {t(`terms&conditions.subtitle6.description3`)}</p>
<p style={{textAlign: "justify"}}><strong>6.4</strong> {t(`terms&conditions.subtitle6.description4`)}</p>
<p style={{textAlign: "justify"}}><strong>6.5</strong> {t(`terms&conditions.subtitle6.description5`)}</p>
<p style={{textAlign: "justify"}}>&nbsp;7.&nbsp;<strong>{t(`terms&conditions.subtitle7.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}> {t(`terms&conditions.subtitle7.description1`)}</p>
<p style={{textAlign: "justify"}}> {t(`terms&conditions.subtitle7.description2`)}</p>
<ul style={{textAlign: "justify"}}>
<li>{t(`terms&conditions.subtitle7.list1`)}</li>
<li>{t(`terms&conditions.subtitle7.list2`)}</li>
<li>{t(`terms&conditions.subtitle7.list3`)}</li>
<li>{t(`terms&conditions.subtitle7.list4`)}</li>
<li>{t(`terms&conditions.subtitle7.list5`)}</li>
<li>{t(`terms&conditions.subtitle7.list6`)}</li>
</ul>
<p>&nbsp;</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle8.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle8.description1`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle8.description2`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle8.description3`)} <a href={`mailto:${t('terms&conditions.subtitle8.email')}`}>{t(`terms&conditions.subtitle8.email`)}</a>. {t(`terms&conditions.subtitle8.description4`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle8.description5`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle9.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle9.description1`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle9.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle10.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle10.description1`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle10.description2`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle10.description3`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle10.description4`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle10.description5`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle11.subtitle1`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle11.description1`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle11.description3`)}</p>

<table
//style="border-collapse:collapse;border:none;"
>
    <tbody>
        <tr>
            <td style={{width: "220.7pt", border: "1pt solid windowtext" , padding: "0cm 5.4pt",verticalAlign: "top" }} >
                <p
                //style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><strong><span
                //style='font-family:"Arial",sans-serif;'
                >{t(`terms&conditions.subtitle11.subtitle2`)}</span></strong></p>
            </td>
            <td
           //style{{width: "220.7pt", border-top: "1pt solid windowtext", border-right: "21pt solid windowtext", border-bottom: "1pt solid windowtext", border-image: initial;border-left: none;padding: 0cm 5.4pt;vertical-align: top }}
            >
                <p
                //style={{margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><strong><span
                //style='font-family:"Arial",sans-serif;'
                >{t(`terms&conditions.subtitle11.subtitle3`)}</span></strong></p>
            </td>
        </tr>
        <tr>
            <td
            //style="width: 220.7pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;"
            >
                <p
                //</tr>style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><span
                //style='font-family:"Arial",sans-serif;'
                >{t(`terms&conditions.subtitle11.description4`)}</span></p>
            </td>
            <td
            //style="width: 220.7pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;"
            >
                <p
                //style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><span
                //style='font-family:"Arial",sans-serif;'
                >{t(`terms&conditions.subtitle11.description5`)}</span></p>
            </td>
        </tr>
        <tr>
            <td
            //style="width: 220.7pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;"
            >
                <p
                //style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><span
                //style='font-family:"Arial",sans-serif;'
                >{t(`terms&conditions.subtitle11.description6`)}</span></p>
            </td>
            <td
            //style="width: 220.7pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;"
            >
                <p
                //style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><span
                //style='font-family:"Arial",sans-serif;'
                >{t(`terms&conditions.subtitle11.description7`)}</span></p>
            </td>
        </tr>
    </tbody>
</table>
{/* {<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;background:white;'></p>}                        */}
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle11.description8`)}<a href={`mailto:${t('t(`terms&conditions.subtitle11.email')}`}> {t(`terms&conditions.subtitle11.email`)}</a>.</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle12`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.description12`)}</p>
<p style={{textAlign: "justify"}}><strong>8. {t(`terms&conditions.subtitle13.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>8.1</strong> {t(`terms&conditions.subtitle13.description1`)}</p>
<p style={{textAlign: "justify"}}><strong>8.2</strong> {t(`terms&conditions.subtitle13.description2.1`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle13.description2.2`)}</p>
<p style={{textAlign: "justify"}}><strong>8.3</strong> {t(`terms&conditions.subtitle13.description3`)}</p>
<p style={{textAlign: "justify"}}><strong>8.4</strong> {t(`terms&conditions.subtitle13.description4.1`)}</p>
<p style={{textAlign: "justify"}}><strong>a)</strong> {t(`terms&conditions.subtitle13.description4.2`)}</p>
<p style={{textAlign: "justify"}}><strong>{"b)"}</strong> {t(`terms&conditions.subtitle13.description4.3`)}</p>
<p style={{textAlign: "justify"}}><strong>{"c)"}</strong> {t(`terms&conditions.subtitle13.description4.4`)}</p>
<p style={{textAlign: "justify"}}><strong>8.5</strong> {t(`terms&conditions.subtitle13.description5`)}</p>
<p style={{textAlign: "justify"}}><strong>8.6</strong> {t(`terms&conditions.subtitle13.description6`)}</p>
<p style={{textAlign: "justify"}}><strong>8.7</strong> {t(`terms&conditions.subtitle13.description7`)}</p>
<p style={{textAlign: "justify"}}><strong>8.8</strong> {t(`terms&conditions.subtitle13.description8`)}</p>
<p style={{textAlign: "justify"}}><strong>8.9</strong> {t(`terms&conditions.subtitle13.description9`)}</p>
<p style={{textAlign: "justify"}}><strong>{t(`terms&conditions.subtitle14.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>9.1</strong> {t(`terms&conditions.subtitle14.description1`)}</p>
<p style={{textAlign: "justify"}}><strong>9.2</strong> {t(`terms&conditions.subtitle14.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>9.3</strong> {t(`terms&conditions.subtitle14.description3.1`)}</p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle14.description3.2`)}</p>
<p style={{textAlign: "justify"}}><strong>9.4</strong> {t(`terms&conditions.subtitle14.description4`)}</p>
<p style={{textAlign: "justify"}}><br /> <strong>9.5</strong> {t(`terms&conditions.subtitle14.description5`)}</p>
<p style={{textAlign: "justify"}}><strong>9.6</strong> {t(`terms&conditions.subtitle14.description6`)}</p>
<p style={{textAlign: "justify"}}><strong>9.7</strong> {t(`terms&conditions.subtitle14.description7`)}</p>
<p style={{textAlign: "justify"}}><strong>9.8</strong> {t(`terms&conditions.subtitle14.description8`)}</p>
<p style={{textAlign: "justify"}}><strong>9.9</strong> {t(`terms&conditions.subtitle14.description9`)}</p>
<p style={{textAlign: "justify"}}><strong>10. {t(`terms&conditions.subtitle15`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.description15`)}</p>
<p style={{textAlign: "justify"}}><strong>11. {t(`terms&conditions.subtitle16.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>11.1</strong> {t(`terms&conditions.subtitle16.description1`)}</p>
<p style={{textAlign: "justify"}}><strong>11.2</strong> {t(`terms&conditions.subtitle16.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>11.3</strong> {t(`terms&conditions.subtitle16.description3`)}</p>
<p style={{textAlign: "justify"}}><strong>12. {t(`terms&conditions.subtitle17.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}>{t(`terms&conditions.subtitle17.description`)}</p>
<p style={{textAlign: "justify"}}><strong>12.1</strong> {t(`terms&conditions.subtitle17.description1`)}</p>
<p style={{textAlign: "justify"}}><strong>12.2</strong> {t(`terms&conditions.subtitle17.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>12.3</strong> {t(`terms&conditions.subtitle17.description3`)}</p>
<p style={{textAlign: "justify"}}><strong>13 {t(`terms&conditions.subtitle18.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>13.1</strong> {t(`terms&conditions.subtitle18.description1`)}</p>
<p style={{textAlign: "justify"}}><strong>13.2</strong> {t(`terms&conditions.subtitle18.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>13.3</strong> {t(`terms&conditions.subtitle18.description3`)}</p>
<p style={{textAlign: "justify"}}><strong>13.4</strong> {t(`terms&conditions.subtitle18.description4`)}</p>
<p style={{textAlign: "justify"}}><strong>14. {t(`terms&conditions.subtitle19.subtitle`)}</strong></p>
<p style={{textAlign: "justify"}}><strong>14.1</strong> {t(`terms&conditions.subtitle19.description1`)}</p>
<p style={{textAlign: "justify"}}><strong>14.2</strong> {t(`terms&conditions.subtitle19.description2`)}</p>
<p style={{textAlign: "justify"}}><strong>14.3</strong> {t(`terms&conditions.subtitle19.description3`)}</p>
<p style={{textAlign: "justify"}}><strong>14.4</strong> {t(`terms&conditions.subtitle19.description4`)}</p>
<p style={{textAlign: "justify"}}><strong>14.5</strong> {t(`terms&conditions.subtitle19.description5`)}</p>
<p style={{textAlign: "justify"}}><strong>14.6</strong> {t(`terms&conditions.subtitle19.description6`)}</p>
<p style={{textAlign: "justify"}}><strong>14.7</strong> {t(`terms&conditions.subtitle19.description7`)}</p>
<p style={{textAlign: "justify"}}><strong>14.8</strong> {t(`terms&conditions.subtitle19.description8`)}</p>
<p style={{textAlign: "justify"}}><strong>14.9</strong> {t(`terms&conditions.subtitle19.description9`)}</p>
<p style={{textAlign: "justify"}}><strong>14.10</strong> {t(`terms&conditions.subtitle19.description10`)}</p>

    </div>
  </div>

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('terms&conditions.close')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}