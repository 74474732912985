import Header from "../layouts/header";
import Title from "../components/title";
import RequestPlanForm from "../components/request-plan/request-plan-form";
import Footer from "../components/footer";
import Loader from "../components/loader";

import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";
import { useTranslation } from "react-i18next";

function RequestPlan(props) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'
    return (
        <>
            <ContextPlansAndServicesProvider>
                <Header {...props}/>
                <Title title={t('requestPlan.fillInformation')} />
                <RequestPlanForm />
                <Footer />
                <Loader/>
            </ContextPlansAndServicesProvider>
        </>
    );
}

export default RequestPlan;