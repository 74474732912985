
import Title from "../../components/title";
import SelectPlan from "../../components/plans-and-services/select-plan-user";


import { ContextPlansAndServicesProvider, PlansAndServicesContext } from "../../contexts/contex-plans-and-services";

export default function UserPlan(props) {
    return (
       <> <ContextPlansAndServicesProvider>
            <SelectPlan />
            </ContextPlansAndServicesProvider>
        </>
           
    )
}