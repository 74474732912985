import * as React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import UpdateIcon from '@mui/icons-material/Update';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { useTranslation } from "react-i18next";

export default function NestedList() {
    const [t, i18next] = useTranslation("global")

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: 0 }}
            // component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <Link
                to={'/admin/userlist'}
                style={{
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)'
                }}
            >
                <ListItemButton>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("board.adminSection.users")} />
                </ListItemButton>
            </Link>
            <Link
                to={'/admin/updateplan'}
                style={{
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)'
                }}
            >
                <ListItemButton>
                    <ListItemIcon>
                        <UpdateIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("board.adminSection.planUpdate")} />
                </ListItemButton>
            </Link>
            <Link
                to={'/admin/emulatecase'}
                style={{
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)'
                }}
            >
                <ListItemButton>
                    <ListItemIcon>
                        <TroubleshootIcon />
                    </ListItemIcon>
                    <ListItemText primary="Emular caso de estudio" />
                </ListItemButton>
            </Link>
        </List>
    );
}