import axios from 'axios';
import Grid from '@mui/material/Grid';
import HeaderUser from './header-user';
import DrawerUser from "./Drawer";
import  ModalDialogComponent from '../../components/dialog/dialog-component'
import { Outlet } from "react-router-dom";
import React, { useContext, useState, useEffect } from 'react';
import { UserContextProvider, UserContext } from '../../contexts/context-layout-user';
import jwt_decode from "jwt-decode";
import { renewalLicense } from "../../actions/emailNotifications";


export default function LayoutUser(props) {
    const [loader, setLoader] = useState(false);
    const [caseSelectOwner, setCaseSelectOwner] = useState(null);
    const [filteredCases, setFilteredCases] = useState([]);
    const [filteredLayers, setFilteredLayers] = useState([]);
    const [modalMessages, setModalMessages] = useState(false);
    const [message, setMessage] = useState(false);
    const [ data, setData ]= useState( {} );
    const [ reminderMessage, setReminderMessage ] = useState( false );

    useEffect( () => {
        const caseSelect = window.location.search ? window.location.search.split('?')[1].split('case=')[1] : null

        if( caseSelect ){
            setLoader( true )
            setCaseSelectOwner( caseSelect ) 
        };

        let token = localStorage.getItem("saxToken");
        const infoUser = token ? jwt_decode(token) : null;


        const endDatee= new Date ( infoUser.licenseExpirationTime );
        const endDateYear= endDatee.getFullYear();
        const endDateMonth= endDatee.getMonth() + 1;
        const endDateDay= endDatee.getDate() + 1;
        const endDate3M= new Date( endDateYear, endDateMonth - 4, endDateDay );
        const actualDate= new Date ( Date.now() );
        const isDemo= infoUser.isDemo ? ' demo' : '';

        const dataUser= {
            name: infoUser.name,
            lastName: infoUser.lastName,
            email: infoUser.email,
            company: infoUser.typeAccount,
            isDemo: infoUser.isDemo ? 'Sí' : 'No',
            endDateYear: endDateYear,
            endDateMonth: endDateMonth,
            endDateDay:  endDateDay
        };

        setData( dataUser );
        
            
        if ( endDate3M <= actualDate ) {
            setReminderMessage( true ); 

            if( localStorage.getItem( "control" ) ){
                    
                setModalMessages( true );                 
                setMessage( `Recuerde que su licencia${ isDemo } expira el ${ endDateDay }/${ endDateMonth }/${ endDateYear }. Contacte el administrador para actualizar su licencia.` );
                localStorage.removeItem("control");    
            };                

        } else {
            setModalMessages( false );                
            localStorage.removeItem( "control" );
        };
        
    }, [] )
    


    return (
        <>
          
        <ModalDialogComponent
        view = {modalMessages}
        tittle ={'Notificación'}
        message={message}
        buttons={[
            {value:'Aceptar',onclick:()=>{setModalMessages(false)}}
        ]}
        />
            <UserContextProvider 
            case={caseSelectOwner}
            >
              
                <Grid container spacing={0} >
                    <Grid item xs={12}>
                        <HeaderUser {...props } reminderMessage= { reminderMessage } data= { data }/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <DrawerUser
                                    filteredCases={filteredCases}
                                    setFilteredCases={setFilteredCases}
                                    filteredLayers={filteredLayers}
                                    setFilteredLayers={setFilteredLayers}
                                    {...props}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={9}
                                id="detail"
                                style={{
                                    padding: 30,
                                    height: "calc(100vh - 67px)"
                                }}
                            >

                                <Outlet />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </UserContextProvider>  

        </>
    )
}