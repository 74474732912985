import React, { useContext, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import StarBorder from '@mui/icons-material/StarBorder';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import logoSax from "../../assets/logo-sax-trans.png";
import UserProfile from "./user-profile";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ListItemIcon from '@mui/material/ListItemIcon';
import SendIcon from '@mui/icons-material/Send';
import ListSubheader from '@mui/material/ListSubheader';
import DraftsIcon from '@mui/icons-material/Drafts';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from "react-i18next";

import { renewalLicense } from "../../actions/emailNotifications";
import { UserContext } from '../../contexts/context-layout-user';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { errors } from '../../config/errors';


export default function HeaderUser(props) {

    const [open, setOpen] = useState(false);
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [emailSent, setEmailSent ] = React.useState( false );

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChangeLanguage  = (lng) => {

        // window.location.reload()
        handleClose();
        localStorage.setItem("i18nextLng", lng)
        i18next.changeLanguage(lng);
    };

    const emailNotification= ( data ) => {

        try {
            renewalLicense( data ).then( res => { setEmailSent( true ) } );

        } catch (error) {
            errors.addErroLog(
                {
                  data: {
                    url: window.location,
                    error: error.toString(),
                    message: error.message,
                    file: error.file,
                    line: error.line
                  }
                 }).then();
            console.log( `Error sending email, error: ${ error }` );
        };
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlert= () => {
        setEmailSent( false );
    };


    return (
        <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} style={{ backgroundColor: "white", color: "black", boxShadow: "none", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} >
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ display: 'flex', }}>
                    <Link to={`/`} style={{ display: 'flex' }}>
                        <img src={logoSax} alt="" style={{ width: 100 }} />
                    </Link>
                </Typography>

                { props.reminderMessage &&
                    <div style= { { color: 'red' } }>
                        <p> { `Su licencia expira el ${ props.data.endDateDay }/${ props.data.endDateMonth }/${ props.data.endDateYear }. Renuévala ` }
                            <a style= { { cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline' } } onClick= { () => { emailNotification( props.data ) } }> aquí. </a>
                        </p>
                    </div>
                }

                <div style={{ display: "flex" }}>
                    <Typography variant="subtitle2" gutterBottom sx={{ flexGrow: 1 }} style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 0 }}>
                        <Divider/>
                        <ListItem button>
                        <Link to={`/`} style={{ textDecoration: "none", marginRight: 10 }}>
                            {t("board.homePage")}
                        </Link>

                        </ListItem>

                    </Typography>

                    <UserProfile {...props}/>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <LanguageIcon style={{color: "#551A8B"}}></LanguageIcon>
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => {handleChangeLanguage("es")}}>{language === "es" ? "Español" : "Spanish"}</MenuItem>
                            <MenuItem onClick={() => {handleChangeLanguage("en")}}>{language === "es" ? "English" : "English"}</MenuItem>
                        </Menu>
                    </Box>

                    <Snackbar
                        open={emailSent}
                        autoHideDuration={6000}
                        onClose={handleCloseAlert}
                        anchorOrigin={{vertical: 'top', horizontal: 'right'} }
                    >
                        <Alert onClose={handleCloseAlert} severity={"success"} sx={{ width: '100%' }}>
                            Su solicitud de renovación ha sido enviada
                        </Alert>
                    </Snackbar>

                </div>
            </Toolbar>
        </AppBar>
    );
}