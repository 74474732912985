import React, { useContext } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PushPinIcon from '@mui/icons-material/PushPin';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Notifications from '../../components/notifications';
import ModalCaseInformation from '../../components/user-admin/modal-case-information';

import { Link } from "react-router-dom";

function FlexibilityComponent(props) {
    const { layers } = props;

    let smallestObject = layers.reduce((prev, current) => (prev.flexibility < current.flexibility) ? prev : current);

    function retunrColor(flexibility) {
        let color = '';
        if (flexibility > 79) {
            color = 'rgb(0, 153, 255)';
        } else if (flexibility >= 40 && flexibility <= 79) {
            color = 'rgb(245, 169, 5)';
        } else {
            color = 'rgb(210, 119, 73)';
        }
        return color;
    }

    return (
        <div
            style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: 'pointer'
            }}
        >
            <div style={{
                background: retunrColor(smallestObject.flexibility.toFixed(2)),
                // background: retunrColor(40),
                borderRadius: "50%",
                width: 20,
                height: 20,
                marginRight: 10
            }}
            ></div>
            <b>

                {smallestObject.flexibility.toFixed(2)}
            </b>
        </div>
    )
}

export default function CaseList(props) {
    const {
        createCaseComparison,
        filteredCases,
        addLayers,
        newArrayNames,
        setNewArrayNemes,
        pinState,
        indexCaseOne,
        indexCaseTwo,
        handleClickOpenModalCaseInfo,
        seeIcon,
        setSeeIcon
    } = useContext(UserContext);

    const [open, setOpen] = React.useState(true);
    const casosFiltrados = filteredCases;

    const searchLayers = (array, filtro, propiedad) => {
        let layers = array.filter(elemento => filtro.includes(elemento.caseResult[0][propiedad]));
        props.setFilteredLayers(layers)
    }

    React.useEffect(() => {
    }, [filteredCases]);


    React.useEffect(() => {
    }, [seeIcon]);

    React.useEffect(() => {
    }, [pinState]);

    const handleClick = () => {
        setOpen(!open);
    };

    function createArrayNames(name) {
        if (newArrayNames.includes(name)) {
            setNewArrayNemes(newArrayNames.filter(id => id !== name));
        } else {
            setNewArrayNemes([...newArrayNames, name]);
        }
    }


    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}
            // component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {
                filteredCases && filteredCases.map((caseInfo, index) => (
                    // !caseInfo.caseResult[0].caseDescription ?
                    <Grid
                        container
                        spacing={0}
                        key={caseInfo._id}
                        onClick={
                            () => searchLayers(casosFiltrados, [caseInfo.caseResult[0].caseName], 'caseName')
                        }
                    >

                        <Grid
                            item
                            xs={9}
                            style={{
                                borderTop: "0",
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)"
                            }}
                        >
                            <ListItemButton>
                                <Link
                                    to={`/admin/1`}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textDecoration: 'none'
                                    }}
                                >
                                    <ListItemIcon
                                        onClick={() => createCaseComparison(caseInfo, index)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ display: seeIcon[index] ? 'block' : 'none' }}>
                                                <PushPinIcon
                                                    style={pinState[index] ?
                                                        { transform: 'rotate(90deg)', color: 'rgb(210, 119, 73)' } :
                                                        { transform: '' }
                                                    }
                                                />
                                            </div>
                                            {
                                                pinState[index] && index === indexCaseOne ?
                                                    <Tooltip title="Caso de referencia" placement="right">
                                                        <p
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                fontSize: 12,
                                                                color: 'rgb(210, 119, 73)',
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            CR
                                                        </p>
                                                    </Tooltip> : ''
                                            }
                                            {
                                                pinState[index] && index === indexCaseTwo ?
                                                    <Tooltip title="Caso comparado" placement="right">
                                                        <p
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                fontSize: 12,
                                                                color: 'rgb(210, 119, 73)',
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            CC
                                                        </p>
                                                    </Tooltip> : ''
                                            }

                                        </div>
                                    </ListItemIcon>
                                </Link>
                                <Link
                                    to={`/admin/1`}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontSize: '12px !important',
                                        fontWight: 500,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                    onClick={() => addLayers(caseInfo.caseResult[0].caseName, index)}
                                >

                                    <ListItemText
                                        primary={caseInfo.caseResult[0].caseName}
                                    />

                                </Link>

                                <div style={{ display: seeIcon[index] ? 'block' : 'none' }}>
                                    <InfoOutlinedIcon onClick={
                                        () => handleClickOpenModalCaseInfo(caseInfo.caseResult[0].caseName, caseInfo.caseResult[0].caseDescription, caseInfo._id)
                                    }
                                        style={{
                                            color: 'rgb(147, 148, 148)'
                                        }}
                                    />
                                </div>
                            </ListItemButton>
                        </Grid>

                        <Grid
                            item xs={3}
                            style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <FlexibilityComponent
                                layers={caseInfo.caseResult[0].layers}
                            />
                        </Grid>
                    </Grid>

                    // :

                    // <>
                    //     <Grid
                    //         container
                    //         spacing={0}
                    //         key={caseInfo._id}
                    //         onClick={
                    //             () => searchLayers(casosFiltrados, [caseInfo.caseResult[0].caseName], 'caseName')
                    //         }
                    //     >
                    //         <Grid
                    //             item
                    //             xs={8}
                    //             style={{
                    //                 borderTop: "0",
                    //                 borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    //                 borderRight: "1px solid rgba(0, 0, 0, 0.12)"
                    //             }}
                    //         >
                    //             <ListItemButton >
                    //                 <ListItemIcon
                    //                     onClick={() => createCaseComparison(caseInfo, index)}
                    //                 >
                    //                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    //                         <PushPinIcon
                    //                             style={pinState[index] ?
                    //                                 { transform: 'rotate(90deg)', color: 'rgb(210, 119, 73)' } :
                    //                                 { transform: '' }
                    //                             }
                    //                         />
                    //                         {
                    //                             pinState[index] && index === indexCaseOne ?
                    //                                 <Tooltip title="Caso de referencia" placement="right">
                    //                                     <p
                    //                                         style={{
                    //                                             padding: 0,
                    //                                             margin: 0,
                    //                                             fontSize: 12,
                    //                                             color: 'rgb(210, 119, 73)',
                    //                                             fontWeight: 700,
                    //                                         }}
                    //                                     >
                    //                                         CR
                    //                                     </p>
                    //                                 </Tooltip> : ''
                    //                         }
                    //                         {
                    //                             pinState[index] && index === indexCaseTwo ?
                    //                                 <Tooltip title="Caso comparado" placement="right">
                    //                                     <p
                    //                                         style={{
                    //                                             padding: 0,
                    //                                             margin: 0,
                    //                                             fontSize: 12,
                    //                                             color: 'rgb(210, 119, 73)',
                    //                                             fontWeight: 700,
                    //                                         }}
                    //                                     >
                    //                                         CC
                    //                                     </p>
                    //                                 </Tooltip> : ''
                    //                         }

                    //                     </div>
                    //                 </ListItemIcon>
                    //                 <Link
                    //                     to={`/user/2`}
                    //                     style={{
                    //                         textDecoration: 'none',
                    //                         color: 'black',
                    //                         fontSize: '12px !important',
                    //                         fontWight: 500
                    //                     }}
                    //                     onClick={() => addLayers(caseInfo.caseResult[0].caseName)}
                    //                 >
                    //                     <ListItemText
                    //                         primary={caseInfo.caseResult[0].caseName}
                    //                     />
                    //                 </Link>
                    //                 {open
                    //                     ?
                    //                     <ExpandLess onClick={handleClick} />
                    //                     :
                    //                     <ExpandMore onClick={handleClick} />
                    //                 }
                    //             </ListItemButton>
                    //         </Grid>
                    //         <Grid
                    //             item
                    //             xs={4}
                    //             style={{
                    //                 borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    //                 display: "flex",
                    //                 justifyContent: "center",
                    //                 alignItems: "center"
                    //             }}
                    //         >
                    //             <FlexibilityComponent
                    //                 layers={caseInfo.caseResult[0].layers}
                    //             />
                    //         </Grid>

                    //         <Collapse in={open} timeout="auto" unmountOnExit>
                    //             <List component="div" disablePadding>
                    //                 <ListItemButton sx={{ pl: 4 }}>
                    //                     <ListItemIcon>
                    //                         {/* <StarBorder /> */}
                    //                     </ListItemIcon>
                    //                     <ListItemText primary="Prueba" />
                    //                 </ListItemButton>
                    //             </List>
                    //         </Collapse>
                    //     </Grid>
                    //     <Divider />
                    // </>
                ))
            }
            <Notifications />
            <ModalCaseInformation />
        </List>


    );
}