import * as React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

function ConfirmationDialogRaw(props) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="sm"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>{t('requestPlan.planConfirmation')}</DialogTitle>
            <DialogContent dividers>

                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                    <div>
                    {t('requestPlan.thankYouMessage')}
                    </div>
                </DialogContentText>

            </DialogContent>
            <DialogActions>

                <Link to="/">
                    <Button autoFocus onClick={handleCancel}>
                    {t('requestPlan.closeMessage')}
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

export default function ConfirmationDialog({ openModal, setOpenModal }) {
    const [value, setValue] = React.useState('Dione');

    const handleClose = (newValue) => {
        setOpenModal(false);

        if (newValue) {
            setValue(newValue);
        }


    };

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

            <ConfirmationDialogRaw
                id="ringtone-menu"
                keepMounted
                open={openModal}
                onClose={handleClose}
                value={value}
            />
        </Box>
    );
}