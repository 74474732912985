import { useLocation } from "react-router-dom";
import React, { createContext, useState, useEffect } from 'react';
import Header from "../layouts/header";
import Title from "../components/title";
import SelectPlan from "../components/plans-and-services/select-plan";
import Footer from "../components/footer";


import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";
import { useTranslation } from "react-i18next";

export default function PlansAndServices(props) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    let { state } = useLocation();

    const defaultPlan = {
        creation_date: '',
        payment_date: '',
        start_date: '',
        end_date: '',
        time_plan: 1,
        status: '',
        plan_value: 1090,
        total_user: 1,
        is_demo: '',
        features: [
            { feature_id: 5, name: 'Usuario', name_english: 'User', quantity: 1, unite_price: 1090,  }
        ],
    }

    const plan = state && state.plan || defaultPlan;

    return (
        <ContextPlansAndServicesProvider>
            <Header {...props}/>
            <Title title={t(`planPricing.planTable.title`)}/>
            <SelectPlan planHome={plan} />
            <Footer />
        </ContextPlansAndServicesProvider>
    )
}