import * as React from 'react';
import { PlansAndServicesContext } from '../../contexts/contex-plans-and-services';
import { useLocation } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { PhoneInput } from 'react-international-phone';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ModalTermsAndConditions from '../../components/register/modal-terms-and-conditions';
import ModalTreatmentPersonalData from '../../components/register/modal-treatment-personal-data';
import SolicitationConfirmationModal from './solicitation-confirmation-modal';
import AlertOne from '../alert';
import { useTranslation } from 'react-i18next';
import { errors as errorsFront} from '../../config/errors';

const CustomButton = {
    textTransform: 'none',
    background: 'rgba(240,125,24,.1)',
    color: '#f07d18',
    border: '1px solid #f07d18',
    fontWeight: 700,
    marginBottom: 10,
    width: '100%',
    borderRadius: 2,
};

const styleTitle = {
    fontSize: '20px',
    fontWeight: 700,
}

const styleText = {
    color: '#868686',
    fontSize: '14px',
    marginBottom: '10px',
    marginTop: '10px',
    fontWeight: 400,
    textAlign: 'start'
}

function RequestPlanForm() {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'
    const { setOpenAlert, setMessageAlert, submitPlanRequest } = React.useContext(PlansAndServicesContext);

    let { state } = useLocation();
    const plan = state.plan;

    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [checkedTwo, setCheckedTwo] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [emailFormatError, setEmailFormatError] = React.useState(false);
    const [userData, setUserData] = React.useState({ name: '', lastName: '', email: '', phoneNumber: '' });
    const [companyData, setCompanyData] = React.useState({ company: '', nit: '', country: '', region: '', city: '', address: '' });
    const [errors, setErrors] = React.useState({
        name: false,
        lastName: false,
        email: false,
        phoneNumber: false,
        company: false,
        nit: false,
        country: false,
        region: false,
        city: false,
        address: false,
        acceptTermsAndConditions: false,
        authorizeAndAcceptDataProcessing: false,
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regex = /\+57\s*\d+/;

    const resetValues = () => {
        setUserData({ name: '', lastName: '', email: '', phoneNumber: '' });
        setCompanyData({ company: '', nit: '', country: '', region: '', city: '', address: '' });
        setErrors({
            name: false,
            lastName: false,
            email: false,
            phoneNumber: false,
            company: false,
            nit: false,
            country: false,
            region: false,
            city: false,
            address: false,
            acceptTermsAndConditions: false,
            authorizeAndAcceptDataProcessing: false,
        });
        setChecked(false);
        setCheckedTwo(false);
    }

    const requestInfo = async (data) => {
        try {
            const info = await submitPlanRequest(data);
            return info;
        } catch (error) {
            errorsFront.addErroLog(
                {
                  data: {
                    url: window.location,
                    error: error.toString(),
                    message: error.message,
                    file: error.file,
                    line: error.line
                  }
                }).then();
            console.log(error);
        }
    }

    const handleChangeValues = (event) => {
        const { value, name } = event.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    }

    const handleChangeValuesTwo = (event) => {
        const { value, name } = event.target;
        setCompanyData({
            ...companyData,
            [name]: value,
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const newErrors = {};
        const userDataCopy = { ...userData };

        if (userDataCopy.name === '') {
            newErrors.name = true;
        }

        if (userDataCopy.lastName === '') {
            newErrors.lastName = true;
        }


        if (userDataCopy.email === '') {
            newErrors.email = true;
        } else if (!emailRegex.test(userDataCopy.email)) {
            newErrors.email = true;
            setEmailFormatError(true);
        }

        if (phoneNumber === '') {
            newErrors.phoneNumber = true;
        } else if (regex.test(phoneNumber)) {
            userDataCopy.phoneNumber = phoneNumber;
        } else {
            newErrors.phoneNumber = true;
        }

        if (companyData.company === '') {
            newErrors.company = true;
        }

        if (companyData.nit === '') {

        }

        if (companyData.country === '') {
            newErrors.country = true;
        }

        if (companyData.region === '') {
            newErrors.region = true;
        }

        if (companyData.city === '') {
            newErrors.city = true;
        }

        if (companyData.address === '') {
            newErrors.address = true;
        }

        if (checked === false) {
            newErrors.acceptTermsAndConditions = true;
        }

        if (checkedTwo === false) {
            newErrors.authorizeAndAcceptDataProcessing = true;
        }

        setUserData(userDataCopy);

        setErrors(newErrors);
        const thereAreErrors = Object.values(newErrors).some((error) => error);

        if (thereAreErrors) {
            setMessageAlert(t('requestPlan.requiredData'));
            setOpenAlert(true);
        } else {
            const data = {
                planData: plan,
                userData: userDataCopy,
                companyData: companyData,
                acceptTermsAndConditions: checked,
                authorizeAndAcceptDataProcessing: checkedTwo,
                requestStatus: 0,
            };

            const info = await requestInfo(data);

            if (info.status === 200) {
                resetValues();
                setOpenModal(true);
            }

        }
    };

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleCheckboxChangeTwo = (event) => {
        setCheckedTwo(event.target.checked);
    };

    return (
        <Container fixed style={{ marginBottom: '40px' }}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={6} sx={{ p: { xs: '15px', md: '35px 75px 35px 35px' } }}>
                    <Paper style={{ padding: 20 }} elevation={3}>
                        <h2 style={styleTitle}>{t('requestPlan.summaryTitle')}</h2>
                        <Divider style={{ marginBottom: 3 }} />

                        {

                            plan.features.map((plan, index) => (
                                <React.Fragment key={index}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                    >

                                        {plan.name === 'Usuario'
                                            ? <p style={styleText}>{`${t(`planPricing.planTable.${plan.name}`)} x ${plan.quantity}`}</p>
                                            : <p style={styleText}>{t(`planPricing.planTable.${plan.name}`)}</p>

                                        }

                                        <Typography
                                            component="div"
                                            style={{
                                                fontSize: 24,
                                                marginBottom: 10,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: 18,
                                                    marginRight: 5,
                                                    color: '#aeaeae'
                                                }}
                                            >
                                                {
                                                    plan.name === 'Canal' || plan.name === 'Vigencia'
                                                        ? ('')
                                                        : ('USD')
                                                }
                                            </span>

                                            <span style={{ fontWeight: 700, fontSize: 18, }}>
                                                {`$${Number(plan.unite_price).toLocaleString('en-US')}`}
                                            </span>

                                        </Typography>

                                    </div>
                                    <Divider style={{ marginBottom: 2, marginTop: 3 }} />
                                </React.Fragment>
                            ))
                        }

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                        >

                            <p style={
                                {
                                    color: '#333',
                                    fontSize: '16px',
                                    marginBottom: '10px',
                                    marginTop: '10px',
                                    fontWeight: 700,
                                    textAlign: 'start'
                                }
                            }
                            >{t('requestPlan.summarytotalPayment')}</p>

                            <Typography
                                component="div"
                                style={{
                                    fontSize: 24,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight: 400,
                                        fontSize: 18,
                                        marginRight: 5,
                                        color: '#aeaeae'
                                    }}
                                >
                                    USD
                                </span>

                                <span style={{ fontWeight: 700, fontSize: 24, color: '#f07d18' }}>
                                    {`$${Number(plan.plan_value).toLocaleString('en-US')}`}
                                </span>

                            </Typography>

                        </div>
                        <Divider style={{ marginBottom: 2, marginTop: 3 }} />

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                        >
                            <Typography
                                component="div"
                                style={{
                                    fontSize: 12,
                                    marginBottom: 30,
                                    marginTop: 10,
                                    color: 'inherit',
                                    fontWeight: 500,
                                    lineHeight: 1.1
                                }}
                            >
                                {t('requestPlan.paymentDisclaimer')}

                            </Typography>

                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} sx={{ p: { xs: '0 15px 0 15px', md: '0 0 0 40px' }, borderLeft: { xs: 'none', md: '1px solid #e7e7e7' } }}>
                    <form onSubmit={handleSubmit}>

                        <Grid item xs={12}>
                            <h2>{t('requestPlan.requiredInformation')}</h2>
                        </Grid>
                        <Grid container spacing={2} >

                            <Grid item xs={12} md={6} >
                                <TextField
                                    id="outlined-basic"
                                    name='name'
                                    label={`${t('requestPlan.firstName')} *`}
                                    variant="outlined"
                                    size="small"
                                    value={userData.name}
                                    onChange={handleChangeValues}
                                    error={errors.name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-basic"
                                    name='lastName'
                                    label={`${t('requestPlan.lastName')} *`}
                                    variant="outlined"
                                    size="small"
                                    value={userData.lastName}
                                    onChange={handleChangeValues}
                                    error={errors.lastName}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-basic"
                                    name='email'
                                    label={`${t('requestPlan.email')} * ${emailFormatError ? 'Formato incorrecto' : ''}`}
                                    variant="outlined"
                                    size="small"
                                    value={userData.email}
                                    onChange={handleChangeValues}
                                    error={errors.email}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <PhoneInput
                                    defaultCountry="co"
                                    value={phoneNumber}
                                    onChange={(phone) => setPhoneNumber(phone)}
                                    inputStyle={{ borderColor: errors.phoneNumber && 'red', width: '100%' }}
                                    placeholder={`${t('requestPlan.phone')} *`}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-basic"
                                    name='company'
                                    label={`${t('requestPlan.company')} *`}
                                    variant="outlined"
                                    size="small"
                                    value={companyData.company}
                                    onChange={handleChangeValuesTwo}
                                    error={errors.company}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-basic"
                                    name='nit'
                                    label={t('requestPlan.taxId')}
                                    variant="outlined"
                                    size="small"
                                    value={companyData.nit}
                                    onChange={handleChangeValuesTwo}
                                    error={errors.nit}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-basic"
                                    name='country'
                                    label={`${t('requestPlan.country')} *`}
                                    variant="outlined"
                                    size="small"
                                    value={companyData.country}
                                    onChange={handleChangeValuesTwo}
                                    error={errors.country}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-basic"
                                    name='region'
                                    label={`${t('requestPlan.region')} *`}
                                    variant="outlined"
                                    size="small"
                                    value={companyData.region}
                                    onChange={handleChangeValuesTwo}
                                    error={errors.region}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-basic"
                                    name='city'
                                    label={`${t('requestPlan.city')} *`}
                                    variant="outlined"
                                    size="small"
                                    value={companyData.city}
                                    onChange={handleChangeValuesTwo}
                                    error={errors.city}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-basic"
                                    name='address'
                                    label={`${t('requestPlan.address')} *`}
                                    variant="outlined"
                                    size="small"
                                    value={companyData.address}
                                    onChange={handleChangeValuesTwo}
                                    error={errors.address}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
                            </Grid>

                            <div style={{ textAlign: 'start', width: '100%' }}>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'start',
                                        marginLeft: 20
                                    }}
                                >
                                    <div
                                        style={{
                                            color: '#868686',
                                            fontXeight: 700,
                                            fontSize: 14
                                        }}
                                    >

                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                            }}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label=""
                                                checked={checked}
                                                onChange={handleCheckboxChange}
                                            />
                                            {t('requestPlan.acceptTerms')}<ModalTermsAndConditions /> <b>*</b>{`\u00A0`}
                                            {errors.acceptTermsAndConditions &&
                                                <span
                                                    style={{ color: 'red' }}
                                                >
                                                    {`${t('requestPlan.required')} *`}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            color: '#868686',
                                            fontXeight: 700,
                                            fontSize: 14
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                checked={checkedTwo}
                                                onChange={handleCheckboxChangeTwo}
                                                label=""
                                            />
                                            {t('requestPlan.acceptTerms')}<ModalTreatmentPersonalData /> <b>*</b>
                                            {`\u00A0`}
                                            {errors.authorizeAndAcceptDataProcessing &&
                                                <span
                                                    style={{ color: 'red' }}
                                                >
                                                    {`${t('requestPlan.required')} *`}
                                                </span>
                                            }
                                        </div>
                                        <div style={{
                                            textAlign: 'start',
                                            marginTop: 15
                                        }}
                                        >
                                            <Button
                                                data-gtm-id='request-purchase'
                                                variant="outlined"
                                                size="large"
                                                type="submit"
                                                style={CustomButton}
                                            >
                                            {t('requestPlan.requestButton')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Grid>
                    </form>
                </Grid>
            </Grid >

            <AlertOne />
            <SolicitationConfirmationModal openModal={openModal} setOpenModal={setOpenModal} />
        </Container >
    );
}

export default RequestPlanForm;