import { cases } from "../config/cases";


export const casesConsult = () => {
  return cases.casesConsult().then(response => response)
};

export const casesCreated = (caseCreate) => {
  return cases.casesCreated(caseCreate).then(response => response)
};

export const editCase = (data) => {
  return cases.editCase(data).then(response => response)
};
export const getCasesMenu = (data) => {
  return cases.getCasesMenu(data).then(response => response)
};

