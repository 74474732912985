import * as React from 'react';
import  { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import SvgIcon from '@mui/material/SvgIcon';
import {editCase} from '../../actions/cases'
import { useTranslation } from "react-i18next";
import { errors } from '../../config/errors';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    // backgroundColor: purple[500],
    backgroundColor: '#3F008E',
    '&:hover': {
        //   backgroundColor: purple[700],
        backgroundColor: '#7220DB',
    },
}));

const ColorButtonCancel = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#FF6A13',
    '&:hover': {
        backgroundColor: '#FF5722',
    },
}));


function EditIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M22 24H2v-4h20v4zM13.06 5.19l3.75 3.75L7.75 18H4v-3.75l9.06-9.06zm4.82 2.68-3.75-3.75 1.83-1.83c.39-.39 1.02-.39 1.41 0l2.34 2.34c.39.39.39 1.02 0 1.41l-1.83 1.83z" />
        </SvgIcon>
    );
}

export default function ModalEditeCase(props) {
    const [t, i18next] = useTranslation("global")
    const { setFilteredCasesMenu, filteredCasesMenu, setLayers, setCases, cases} = useContext(UserContext);
    const [open, setOpen] = React.useState(false);
    const [labelName, setLabelNeme] = React.useState(t("board.caseEdition.name"));
    const [labelDescription, setLabelDescription] = React.useState(t("board.caseEdition.description"));
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [statusErrorName, setStatusErrorName] = React.useState(false);
    const [statusErrorDescrip, setStatusErrorDescrip] = React.useState(false);
    const [errorMessageName, setErrorMessageName] = React.useState('');
    const [errorMessageDescrip, setErrorMessageDescrip] = React.useState('');


    const formRef = React.useRef(null);


    const handleSubmit = (event) => {
        event.preventDefault();

        let correctName = false;
        let correcDescription = false;

        if (name === '') {
            setStatusErrorName(true);
            setLabelNeme('Error');
            setErrorMessageName("Entrada incorrecta");
        } else {
            setStatusErrorName(false);
            setLabelNeme('Nombre');
            setErrorMessageName("");
            correctName = true;
            correcDescription = true;
        }

        if (correctName && correcDescription) {
            const jsonCase = {
                name: name,
                description: description
            }



            editCase({jsonCase, idCase:props.idCase }).then((response)=>{
                if (response.status === 200) {
                    handleClose();
                    props.setServerResponse(response.status);

                    let temporal = filteredCasesMenu.map(row => {
                            if(row._id === props.idCase ) {
                                let caseLayer_ = {...row,
                                    'caseResult':[{...row.caseResult[0], 'caseName' : jsonCase.name,
                                        'caseDescription' : jsonCase.description || ''}]}
                            setLayers(caseLayer_)
                                return caseLayer_}
                            return row
                        }
                       )

                    setCases(cases.map(row => {
                        if(row._id === props.idCase ) {
                            let caseLayer_ = {...row,
                                'caseResult':[{...row.caseResult[0], 'caseName' : jsonCase.name,
                                    'caseDescription' : jsonCase.description || ''}]}
                            return caseLayer_}
                        return row
                    }
                   ))
                    setFilteredCasesMenu(temporal)
                }

            }).catch(error => {
                errors.addErroLog(
                    {
                      data: {
                        url: window.location,
                        error: error.toString(),
                        message: error.message,
                        file: error.file,
                        line: error.line
                      }
                    }).then();
                console.log(error);
            });
        }

    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setDescription('');
        setStatusErrorName(false);
        setLabelNeme('Nombre');
        setErrorMessageName("");
        setStatusErrorDescrip(false);
        setLabelDescription('Descripción');
        setErrorMessageDescrip('');
    };

    return (
        <Box>
            <EditIcon color="action" onClick={handleClickOpen} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <form ref={formRef} onSubmit={handleSubmit}>
                    <DialogTitle>{t("board.caseEdition.caseEditing")}</DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            error={statusErrorName}
                            label={labelName}
                            helperText={errorMessageName}
                            fullWidth
                            margin="dense"
                            value={name || props.name &&  props.name}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <TextField
                            // error={statusErrorDescrip}
                            label={labelDescription}
                            helperText={errorMessageDescrip}
                            multiline
                            rows={4}
                            fullWidth
                            margin="dense"
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <ColorButton
                            type='reset'
                            variant="contained"
                            color="error"
                            startIcon={<CancelScheduleSendIcon />}
                            onClick={handleClose}>
                            {t("board.caseEdition.cancel")}
                        </ColorButton>
                        <ColorButtonCancel
                            variant="contained"
                            endIcon={<SendIcon />}
                            onClick={handleSubmit}>
                            {t("board.caseEdition.save")}
                        </ColorButtonCancel>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}