import React, { useContext, useEffect, useState } from 'react';
import { PlansAndServicesContext } from '../../contexts/contex-plans-and-services';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import Image1 from '../../assets/icono_1.png';
import Image2 from '../../assets/icono_2.png';
import Image3 from '../../assets/icono_3.png';
import Image4 from '../../assets/icono_5.png';
import { useTranslation } from 'react-i18next';
import { errors } from '../../config/errors';

const CardHover = styled(Card)({
    boxShadow: '0 2px 5px rgba(0,0,0,.3)',
    height: '100%',
    '&:hover': {
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.3)',
        transition: 'box-shadow 0.3s ease-in-out',
    },
})

const styleTitle = {
    fontSize: '20px',
    fontWeight: 700,
}

const styleText = {
    color: '#868686',
    fontSize: '14px',
    marginBottom: '10px',
    marginTop: '10px',
    fontWeight: 400,
    textAlign: 'start'
}

const styleRadio = {
    border: '1px solid grey',
    padding: '3px 10px 3px 10px',
    borderRadius: 3,
    margin: 0,
    width: '80%'
}

const styleCheck = {
    border: '1px solid grey',
    padding: '0 10px 0 10px',
    borderRadius: 3,
}

function SelectPlan({  planHome }) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    const { infoPlans, requestInfoPlan, openLoader, features, setFeatures } = useContext(PlansAndServicesContext);

    const [plan, setPlan] = useState(planHome );
    const [selectedChannel, setSelectedChannel] = useState("xm");
    const [totalUsers, setTotalUsers] = useState(planHome  && planHome.total_user );
    const [timePlans, setTimePlans] = useState(planHome && planHome.time_plan );

    const [checkedValues, setCheckedValues] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await requestInfoPlan();
            } catch (error) {
                errors.addErroLog(
                    {
                      data: {
                        url: window.location,
                        error: error.toString(),
                        message: error.message,
                        file: error.file,
                        line: error.line
                      }
                    }).then();
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (infoPlans && infoPlans.listOne) {
            createFeatures(planHome && planHome.time_plan, planHome && planHome.total_user);
        }
    }, [infoPlans]);

    useEffect(() => {
        const newCheck = planHome && planHome.features.map(feature => ({
            name: feature.name,
            name_english: feature.name_english,
            active: true
        }));

        setCheckedValues(newCheck);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        let featuresPlan = {};
    }

    const handleChange = (event) => {
        const channelId = event.target.value;
        setSelectedChannel(channelId);
    };

    const handleRadioTimeChange = (event) => {
        setTimePlans(Number(event.target.value));
        createFeatures(Number(event.target.value), totalUsers);
    };

    const validateChecked = (name, name_english) => {

        const valueEnglish = t(`planPricing.planTable${name}`)

        let result = checkedValues.find(
            obj => obj.name_english === name_english || obj.name === name
        );
        if (!result) {
            result = checkedValues.find(
                obj => obj.name_english === valueEnglish || obj.name === valueEnglish
            );
        }
        const isActive = result ? result.active : false
        return isActive;
    }

    const handleCheckboxChange = (event) => {
        const { name, checked, value } = event.target;

        setCheckedValues(prevState => {
            const featureIndex = prevState.findIndex(
                obj => obj.name === name || obj.name_english === name
            );

            if (featureIndex !== -1) {
                const updatedState = [...prevState];
                updatedState[featureIndex].active = checked;
                return updatedState;
            } else {
                const newFeature = {
                    name: name,
                    name_english: language === 'en' ? name : '',
                    active: checked,
                };
                return [...prevState, newFeature];
            }
        });

        const newPlan = { ...plan };
        const featureExists = newPlan.features.some(obj =>  obj.name_english === value || obj.name === value);

        if (featureExists) {
            const filterFeature = newPlan.features.filter(obj => !(obj.name_english === value || obj.name === value));
            newPlan.features = filterFeature;
            newPlan.plan_value = addPrices(newPlan.features);
            setPlan(newPlan);
        } else {
            const filterFeature = features.find(obj => language === 'en' ? obj.name_english === value : obj.name === value);
            const newFeature = { feature_id: filterFeature.id, name: filterFeature.name, name_english: filterFeature.name_english, quantity: 1, unite_price: filterFeature.price }
            newPlan.features.push(newFeature);
            newPlan.plan_value = addPrices(newPlan.features);
            setPlan(newPlan);
        }
    };

    function addPrices(features) {
        let total = 0;
        for (const feature of features) {
            total += feature.unite_price;
        }
        return total;
    }

    const createFeatures = (time, users) => {

        const filterForTime = infoPlans.listOne.find(obj => obj.value === time);
        const filterForUsers = filterForTime.features.find(obj => obj.totalUser === users).features;
        setFeatures(filterForUsers);
        const newPlan = { ...plan };

        const featureUser = filterForUsers.find(obj => obj.name.includes('Usuario'));
        const userGroupFeature = filterForUsers.find(obj => obj.name.includes('Primer grupo 5 usuarios'));

        newPlan.time_plan = time;
        newPlan.total_user = users;

        newPlan.features.map((feature) => {
            if (feature.name === 'Usuario'|| feature.name === 'User') {
                feature.feature_id = featureUser.id;
                feature.quantity = users;
                feature.unite_price = featureUser.price;
            }else if(feature.name === 'Primer grupo 5 usuarios\r\n'){

                feature.feature_id = userGroupFeature.id;
                feature.quantity = users;
                feature.unite_price = userGroupFeature.price;
            }
        });

        const total = addPrices(newPlan.features);
        newPlan.plan_value = total;
        setPlan(newPlan);
    }

    const handleAddUsers = (users) => {

        let newUsers = users;
        if (newUsers === 1) {
            setTotalUsers(5);
            newUsers = 5
        }

        createFeatures(timePlans, newUsers);
    }

    const handleRemoveUsers = (users) => {
        let newUsers = users;
        if (users === 5) {
            setTotalUsers(1);
            newUsers = 1
        }
        createFeatures(timePlans, newUsers);
    }

    return (
        <>
            <Container>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={0} style={{ marginBottom: 35 }} maxWidth="xl">

                        {
                            openLoader &&

                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>

                            ||

                            <React.Fragment>
                                <Grid item xs={12} md={8} style={{ textAlign: 'left' }}>
                                    <h2 style={styleTitle}>{t(`planPricing.planTable.selectChannel`)}</h2>
                                    <p style={styleText}>{t(`planPricing.planTable.channelAssistance`)}</p>

                                    <FormControl>
                                        <InputLabel id="select">{t(`planPricing.planTable.channel`)}</InputLabel>
                                        <Select
                                            labelId="select"
                                            id="demo-simple-select"
                                            value={selectedChannel}
                                            label="Canal"
                                            onChange={handleChange}
                                            sx={{ width: { xs: 350, md: 250 }, marginBottom: 4 }}
                                        >
                                            <MenuItem value='xm'>XM</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Divider style={{ marginBottom: 30 }} />

                                    <h2 style={styleTitle}>{t(`planPricing.planTable.selectPlanDuration`)}</h2>
                                    <p style={styleText}>{t(`planPricing.planTable.durationBenefits`)}</p>

                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={timePlans}
                                        onChange={handleRadioTimeChange}
                                    >
                                        <Grid container direction="row"
                                            justifyContent="center"
                                            alignItems="center" spacing={0}>
                                            {infoPlans.listOne ? infoPlans.listOne.map((time, index) => (
                                                <Grid item xs={6} md={3} key={index} style={{ textAlign: 'center' }} sx={{ mb: { xs: 2 } }}>

                                                    <FormControlLabel
                                                        value={time.value}
                                                        control={<Radio />}
                                                        label={`${time.value} ${t(`planPricing.planTable.${time.unit}`)}`}
                                                        style={styleRadio}
                                                    />

                                                </Grid>

                                            ))

                                                : ''
                                            }
                                        </Grid>
                                    </RadioGroup>

                                    <Divider style={{ marginBottom: 30, marginTop: 30 }} />

                                    <h2 style={styleTitle}>{t(`planPricing.planTable.addFunctionality`)}</h2>
                                    <p style={styleText}>{t(`planPricing.planTable.addFunctionalityText`)}</p>

                                    <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="stretch">
                                        {
                                            features.length > 0 && features.map((feature, index) => (
                                                <Grid item xs={6} md={3} key={index} style={{ textAlign: 'center' }}>
                                                    <CardHover variant="outlined">
                                                        <CardContent style={{ marginBottom: 'auto', position: 'relative' }} sx={{ height: { xs: 520, md: 450 } }}>
                                                            {index === 0 &&
                                                                <img src={Image1} style={{ width: '100%' }} />
                                                            }

                                                            {index === 1 &&
                                                                <img src={Image2} style={{ width: '100%' }} />
                                                            }

                                                            {index === 2 &&
                                                                <img src={Image3} style={{ width: '100%' }} />
                                                            }

                                                            {index === 3 &&
                                                                <img src={Image4} style={{ width: '100%' }} />
                                                            }

                                                            <Typography
                                                                variant="h3"
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 700,
                                                                    color: '#4b4b4a',
                                                                    marginTop: 20,
                                                                    marginBottom: 10
                                                                }}
                                                                gutterBottom
                                                            >
                                                                {feature.name === 'Usuario'
                                                                    ? t('planPricing.planTable.Usuarios')
                                                                    : (language === 'en' ? feature.name_english : feature.name)
                                                                }
                                                            </Typography>

                                                            <Typography
                                                                variant="div"
                                                                style={{
                                                                    fontSize: 12,
                                                                    fontWeight: 400,
                                                                    color: '#868686',
                                                                    marginTop: 20,
                                                                    marginBottom: 10
                                                                }}
                                                            >
                                                                {language === 'en' ? feature.description_english : feature.description}
                                                            </Typography>

                                                            <Typography
                                                                component="div"
                                                                style={{
                                                                    fontSize: 24,
                                                                    marginBottom: 10,
                                                                    position: 'absolute',
                                                                    bottom: 0,
                                                                    left: "50%",
                                                                    transform: "translateX(-50%)"
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight: 400,
                                                                        fontSize: 18,
                                                                        marginRight: 5,
                                                                        color: '#aeaeae'
                                                                    }}
                                                                >
                                                                    {t('planPricing.planTable.currency')}
                                                                </span>

                                                                <span style={{ fontWeight: 700, fontSize: 18, }}>
                                                                    {Number(feature.price).toLocaleString('en-US')}
                                                                </span>

                                                            </Typography>

                                                        </CardContent>
                                                        <CardActions
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                position: 'asolute',
                                                                bottom: 0,
                                                                marginBottom: 10
                                                            }}
                                                        >

                                                            {
                                                                feature.name === 'Usuario' ?
                                                                    <div style={{ display: 'flex' }}>
                                                                        <IconButton aria-label="delete" onClick={() => handleRemoveUsers(totalUsers)} >
                                                                            <RemoveIcon />
                                                                        </IconButton>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                border: '1px solid #858585',
                                                                                padding: '3px 15px 3px 15px',
                                                                                borderRadius: 3,
                                                                                backgroundColor: '#e6e6e6',
                                                                                color: '#858585'
                                                                            }}
                                                                        >
                                                                            {totalUsers}
                                                                        </div>
                                                                        <IconButton aria-label="delete" onClick={() => handleAddUsers(totalUsers)}>
                                                                            <AddIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                    :
                                                                    <div style={styleCheck}>
                                                                        <div>
                                                                            <Checkbox
                                                                                // checked={checkedValues[language === 'en' ? feature.name_english : feature.name] || false}
                                                                                checked={validateChecked(feature.name, feature.name_english)}
                                                                                onChange={handleCheckboxChange}
                                                                                name={language === 'en' ? feature.name_english : feature.name}
                                                                                value={language === 'en' ? feature.name_english : feature.name}
                                                                                data-custom-value="myCustomValue"
                                                                                inputProps={{ 'aria-label': 'check' }}
                                                                            />
                                                                            <span>{t(`planPricing.planTable.add`)}</span>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </CardActions>
                                                    </CardHover>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Paper
                                        sx={{
                                            ml: { xs: 0, md: 4, },
                                            mt: { xs: 4, md: 0, },
                                            p: { xs: 4, md: 3, },
                                        }}
                                        elevation={3}
                                    >
                                        <h2 style={styleTitle}>{t(`planPricing.planTable.orderSummary`)}</h2>
                                        <Divider style={{ marginBottom: 3 }} />

                                        {
                                            plan.features.map((plan, index) => (
                                                <React.Fragment key={index}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}
                                                    >

                                                        {plan.name === 'Usuario'
                                                            ? <p style={styleText}>{`${t(`planPricing.planTable.${plan.name}`)} x ${plan.quantity}`}</p>
                                                            : <p style={styleText}>{t(`planPricing.planTable.${plan.name}`)}</p>

                                                        }

                                                        <Typography
                                                            component="div"
                                                            style={{
                                                                fontSize: 24,
                                                                marginBottom: 10,
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: 18,
                                                                    marginRight: 5,
                                                                    color: '#aeaeae'
                                                                }}
                                                            >
                                                                {
                                                                    plan.name === 'Canal' || plan.name === 'Vigencia'
                                                                        ? ('')
                                                                        : ('USD')
                                                                }
                                                            </span>

                                                            <span style={{ fontWeight: 700, fontSize: 18, }}>
                                                                {`$${Number(plan.unite_price).toLocaleString('en-US')}`}
                                                            </span>

                                                        </Typography>

                                                    </div>
                                                    <Divider style={{ marginBottom: 2, marginTop: 3 }} />
                                                </React.Fragment>
                                            ))

                                        }


                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                        >

                                            <p style={
                                                {
                                                    color: '#333',
                                                    fontSize: '16px',
                                                    marginBottom: '10px',
                                                    marginTop: '10px',
                                                    fontWeight: 700,
                                                    textAlign: 'start'
                                                }
                                            }
                                            >{t(`planPricing.planTable.totalPayment`)}</p>

                                            <Typography
                                                component="div"
                                                style={{
                                                    fontSize: 24,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        color: '#aeaeae'
                                                    }}
                                                >
                                                    USD
                                                </span>

                                                {

                                                    <span style={{ fontWeight: 700, fontSize: 24, color: '#f07d18' }}>
                                                        {`$${Number(plan.plan_value).toLocaleString('en-US')}`}
                                                    </span>
                                                }



                                            </Typography>

                                        </div>
                                        <Divider style={{ marginBottom: 2, marginTop: 3 }} />

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                        >
                                            <Typography
                                                component="div"
                                                style={{
                                                    fontSize: 12,
                                                    marginBottom: 30,
                                                    marginTop: 10,
                                                    color: 'inherit',
                                                    fontWeight: 500,
                                                    lineHeight: 1.1
                                                }}
                                            >
                                                {t(`planPricing.planTable.taxDisclaimer`)}

                                            </Typography>

                                            <Link
                                                to="/solicitarplan"
                                                state={{ plan }}
                                            >
                                                <Button
                                                    data-gtm-id='start-purchase'
                                                    variant='outlined'
                                                    type="submit"
                                                    style={{
                                                        textTransform: 'none',
                                                        background: 'rgba(240,125,24,.1)',
                                                        color: '#f07d18',
                                                        border: '1px solid #f07d18',
                                                        fontWeight: 700,
                                                        marginBottom: 10,
                                                        width: '100%',
                                                        borderRadius: 2,
                                                    }}
                                                >
                                                    {t(`planPricing.planTable.startPurchase`)}
                                                </Button>
                                            </Link>

                                        </div>
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                </form>
            </Container >
        </>
    );
}

export default SelectPlan;