import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function ModalTreatmentPersonalData() {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <b
                onClick={handleClickOpen('paper')}
                style={{
                    marginLeft: 5,
                    marginRight: 5,
                    cursor: 'pointer',

                }}
            >
                 {t(`contact.contactConsentPersonalData`)}
            </b>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth={'lg'}
            >
                <DialogTitle id="scroll-dialog-title">
                    {t(`contact.contactConsentPersonalData`)}
                </DialogTitle>
                <DialogContent dividers >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <div>
                        <embed src="https://ecotradestorage.blob.core.windows.net/projects/Guia_139_Tratamiento_de_datos_personales_V2_20210225.pdf" type="application/pdf" width="900px" height="600px"  />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}