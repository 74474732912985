import { useState, useEffect, useContext, createContext } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import HeaderUser from './header-user';
import DrawerUser from "./Drawer";

import { Outlet } from "react-router-dom";

import { UserContextProvider, UserContext } from '../../contexts/context-layout-user';

export default function LayoutUser(props) {
    const [filteredCases, setFilteredCases] = useState([]);
    const [filteredLayers, setFilteredLayers] = useState([]);
    

    // useEffect(() => {
    //     axios.get('${process.env.BACKEND_URL}/api/v1/cases')
    //         .then(response => {
    //             setCases(response.data.response);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }, []);

    return (
        <>
            <UserContextProvider>
                <Grid container spacing={0} >
                    <Grid item xs={12}>
                        <HeaderUser {...props}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <DrawerUser
                                    // arrayCases={cases}
                                    filteredCases={filteredCases}
                                    setFilteredCases={setFilteredCases}
                                    filteredLayers={filteredLayers}
                                    setFilteredLayers={setFilteredLayers}
                                    {...props}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={9}
                                id="detail"
                                style={{
                                    padding: 30,
                                    height: "calc(100vh - 67px)"
                                }}
                            >
                                {/* {
                                    filteredLayers.length === 0
                                        ?
                                        ('')
                                        :
                                        (<LayersDateils
                                            filteredLayers={filteredLayers}
                                            setFilteredCases={setFilteredCases}
                                            arrayCases={cases}
                                        />)
                                } */}

                                <Outlet />

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </UserContextProvider>
        </>
    )
}