import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';

import ModalUpdatePlan from "../../components/update-plan/modal-update-plan";

import Image1 from '../../assets/icono_1.png';
import Image2 from '../../assets/icono_2.png';
import Image3 from '../../assets/icono_3.png';
import Image4 from '../../assets/icono_5.png';

function UpdatePlan() {
    return (
        <>

            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={6} style={{ textAlign: 'start' }}>
                    <h2>Mi plan</h2>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'end' }}>
                    <ModalUpdatePlan />
                </Grid>

            </Grid>
            <Divider />

            <Grid container spacing={5}>

                <Grid item xs={6}>
                    <Paper elevation={3} style={{ padding: 25, textAlign: 'start', marginTop: 35 }}>
                        <Typography variant="h6" gutterBottom>
                            Vigencia plan actual
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 12 }}>
                            < DateRangeIcon style={{ marginRight: 10, color: '#b0b0b0' }} />
                            <Typography variant="h5" style={{ margin: 0, color: '#ef7a14', fontWeight: 600 }}>
                                12 meses
                            </Typography>

                        </div>

                        <Typography variant="body2" style={{}}>
                            Fecha de inicio: 31/12/2017
                        </Typography>
                        <Typography variant="body2" style={{ marginBottom: 17 }}>
                            Fecha de vencimiento: 21/08/2024
                        </Typography>

                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 12, marginTop: 17 }}>
                            < PersonIcon style={{ marginRight: 10, color: '#b0b0b0' }} />
                            <Typography variant="h5" style={{ margin: 0, color: '#ef7a14', fontWeight: 600 }}>
                                1000 usuarios
                            </Typography>
                        </div>

                        <Typography variant="body2" style={{}}>
                            41 usuarios activos
                        </Typography>

                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={3} style={{ padding: 25, textAlign: 'start', marginTop: 35 }}>
                        <Typography variant="h6" gutterBottom>
                            Toppings seleccionados
                        </Typography>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            marginBottom: 12,
                            borderRadius: 5,
                            background: '#f0f0f0',
                            padding: 15
                        }}
                        >
                            {/* < DateRangeIcon style={{ marginRight: 10 }} /> */}
                            <img src={Image1} alt="" srcSet="" width={80} style={{ marginRight: 10 }} />
                            <Typography variant="body1" style={{ margin: 0, }}>
                                Excel + Sensibilidad de generadores
                            </Typography>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            marginBottom: 12,
                            borderRadius: 5,
                            background: '#f0f0f0',
                            padding: 15
                        }}
                        >
                            <img src={Image2} alt="" srcSet="" width={80} style={{ marginRight: 10 }} />
                            <Typography variant="body1" style={{ margin: 0, }}>
                                Históricos
                            </Typography>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            marginBottom: 12,
                            borderRadius: 5,
                            background: '#f0f0f0',
                            padding: 15
                        }}
                        >
                            <img src={Image3} alt="" srcSet="" width={80} style={{ marginRight: 10 }} />
                            <Typography variant="body1" style={{ margin: 0, }}>
                                Archivos de despacho
                            </Typography>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            marginBottom: 12,
                            borderRadius: 5,
                            background: '#f0f0f0',
                            padding: 15
                        }}
                        >
                            <img src={Image4} alt="" srcSet="" width={80} style={{ marginRight: 10 }} />
                            <Typography variant="body1" style={{ margin: 0, }}>
                                Usuarios
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>

        </>
    );
}

export default UpdatePlan;