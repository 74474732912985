import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import LinearProgress from '@mui/material/LinearProgress';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import {adminGetUsers} from '../../actions/users'
import ModalAddUser from '../../components/user-admin/modal-add-user';
import ModalEditUser from '../../components/user-admin/modal-edit-user';
import ModalDeleteUser from '../../components/user-admin/modal-delete-user';
import { useTranslation } from "react-i18next";
// import ModalAllUser from '../../components/user-admin/modal-all-users';

const columns = [
    {
        id: 'name',
        label: 'name',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'lastname',
        // label: 'ISO\u00a0Code',
        label: 'lastName',
        minWidth: 100,
        align: 'center',
    },
    {
        id: 'phone',
        label: 'phone',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'email',
        label: 'email',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'role',
        label: 'roles',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'actions',
        label: 'actions',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
];

export default function CompaniesList() {
    
    const { usersAdmin, setUsersAdmin,listUsers, setListUsers } = useContext(UserContext);
   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filterValue, setFilterValue] = useState('');
    // const [listUsersComponent, setListUsersComponent] =  useState([]);
    const [originalUsers, setOriginalUsers] = useState(usersAdmin);
    const [openLoader, setOpenLoader] = useState(true)
    const [t, i18next] = useTranslation("global")

    useEffect(() => {
        adminGetUsers().then(response => {
            if(response && response.status === 200) {
            setOpenLoader(false)
            response.response && setListUsers(response.response
                .map(row=> ({...row, rolName: row.user_type === 1 ? "admin": row.user_type === 2 ? "analyst" : row.user_type === 4 ? "developer" : ""})))
            }
        })
        
    }, []);

 
    // useEffect(() => {
    //     filterUsers();
    // }, [filterValue, originalUsers]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleFilter = (e) => {
        const inputValue = e.target.value;
        setFilterValue(inputValue);
    };

    const filterUsers = () => {
        if (filterValue.trim() === '') {
            setUsersAdmin(originalUsers);
        } else {
            const filteredUsers = originalUsers.filter((user) => {
                return user.name.toLowerCase().includes(filterValue.toLowerCase());
            });
            setUsersAdmin(filteredUsers);
        }
    };

   

    return (
        <>
         {openLoader ?

            <Grid item xs={12}>
                <LinearProgress />
            </Grid> : 
        <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={3}>
                                <TextField
                                    id="outlined-basic"
                                    label={t("board.adminSection.usersTable.searchUser")}
                                    variant="outlined"
                                    size="small"
                                    value={filterValue}
                                    onChange={handleFilter}
                                    type='search'
                                />
                            </TableCell>
                            <TableCell align="right" colSpan={4}>
                                <ModalAddUser />
                                {/* <ModalAllUser /> */}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        top: 57,
                                    }}
                                >
                                    {t(`board.adminSection.usersTable.${column.label}`)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                   
                        { listUsers && listUsers.length > 0 && listUsers.filter(ele=> ele.name.toLowerCase().includes(filterValue.toLowerCase()) ||
                        ele.lastname.toLowerCase().includes(filterValue.toLowerCase()) || 
                        ele.email.toLowerCase().includes(filterValue.toLowerCase()) || 
                        ele.rolName && ele.rolName.toLowerCase().includes(filterValue.toLowerCase()) )
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>

                                        <TableCell align='center'>
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {row.lastname}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {row.phonenumber}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {row.email}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {t(`board.adminSection.usersTable.${row.rolName}`)}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={0}
                                            >
                                                <ModalDeleteUser user={row} />
                                                <ModalEditUser user={row} />

                                            </Stack>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                    </TableBody>



                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={listUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("board.casesHistory.rowsPerPage")}
            />
        </Paper>}
        </>
    );
}