import React, { useContext } from 'react';
import { CounterContext } from '../../contexts/context-layout-user';

import Grid from '@mui/material/Grid';
import ModalFlexibilityRanges from './modal-flexibility-ranges';
import FooterMenu from "./footer-menu";
import { useTranslation } from "react-i18next";


export default function DrawerUser(props) {
    /**
     * Translation hook call
     */
    const [t, i18next] = useTranslation("global")

    const arrayCases = props.arrayCases;

    return (
        <Grid container spacing={0} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', display: 'flex' }}>
            <Grid item style={{ flex: 1 }}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={0} justifyContent="center" alignItems="center">
                            <Grid item xs={12} style={{ padding: "10px 0 10px 0", background: "#ccc" }}>
                                <b>{t("board.flexibilityScale")}</b>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                    <Grid item xs={10} style={{ display: "flex", padding: '15px 0 15px 0' }}>

                                        <Grid item xs={4} style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                                            <div style={{ background: '#d27749', borderRadius: "50%", width: 20, height: 20 }}></div>
                                            <span><b>{t("board.critical")}</b></span>
                                        </Grid>
                                        <Grid item xs={4} style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                                            <div style={{ background: '#f5a905', borderRadius: "50%", width: 20, height: 20 }}></div>
                                            <span> <b>{t("board.restrictive")}</b></span>
                                        </Grid>
                                        <Grid item xs={4} style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                                            <div style={{ background: '#09f', borderRadius: "50%", width: 20, height: 20 }}></div>
                                            <span><b>{t("board.flexible")}</b></span>
                                        </Grid>

                                    </Grid>

                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <ModalFlexibilityRanges />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FooterMenu
                        arrayCases={arrayCases}
                        filteredCases={props.filteredCases}
                        setFilteredCases={props.setFilteredCases}
                        filteredLayers={props.filteredLayers}
                        setFilteredLayers={props.setFilteredLayers}
                        {...props}
                    />
                </Grid>

            </Grid>
        </Grid>

    )
}