
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next"
import i18next  from 'i18next';
import { errors } from './config/errors';


const traslateEn = require('./translations/en/global.json');
const traslateEs = require('./translations/es/global.json');
let global_es = traslateEs
let global_en = traslateEn

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('i18nextLng') || 'es',
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

window.onerror = (message, source, lineno, colno, error) => {

  errors.addErroLog({data: {
    url:window.location,
    error: error.toString(),
    message: error.message,
    file: error.file,
    line: error.line
  }}).then()
};

// Captura de rechazos de promesas no manejados
window.onunhandledrejection = (event) => {
  errors.addErroLog({data: {
    url:window.location,
    error: event.reason?.toString(),
    message: event.reason?.toString(),
    file: event.file ? event.file : '' ,
    line: event.line ? event.line : ''
  }}).then()
};


  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  );
  reportWebVitals();
