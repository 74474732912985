import React, { useContext, useState, useForm, useEffect, createContext } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import PropTypes from 'prop-types';
import  ModalDialogComponent from '../../components/dialog/dialog-component'
import TextField from '@mui/material/TextField';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import {editCase} from '../../actions/cases'
import { useTranslation } from "react-i18next";
import infoIcon from '../../assets/inf_icon.png';

import { visuallyHidden } from '@mui/utils';
import { errors } from '../../config/errors';



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'nombre',
    numeric: false,
    disablePadding: true,
    label: 'name',
  },
  {
    id: 'fecha',
    numeric: true,
    disablePadding: false,
    label: 'date',
  },
  {
    id: 'descripción',
    numeric: true,
    disablePadding: false,
    label: 'description',
  },

];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;

    const [t, i18next] = useTranslation("global")

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>

      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(`board.casesHistory.${headCell.label}`)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { setFlterCase, filterCase } = useContext(UserContext);


  const { numSelected } = props;
  const [caseDescription, setCaseDescription] = useState('')
  const [caseName, setCaseName] = useState('')
  const [caseDate, setCaseDate] = useState('')

  const [t, i18next] = useTranslation("global")

  useEffect(() => {

    let filterCaseNew = {
      ...filterCase,
      caseDescription: caseDescription,
      caseName: caseName,
      caseDate: caseDate
    }
    setFlterCase(filterCaseNew)

  }, [caseDescription, caseName, caseDate])

  return (

    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },

      }}
    >
      <TableRow>

        <TableCell class="col-md-4" style={{ paddingLeft: '30px' }}>
          <Typography
            variant="h7"
            id="tableTitle"
            component="div"
          >
            {t("board.casesHistory.studyCases")}
          </Typography>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={caseName}
            onChange={(event) => {
              setCaseName(event.target.value)

            }}
          />
        </TableCell>
        <TableCell class="col-md-4" style={{ paddingLeft: '30px' }}>
          <Typography
            variant="h7"
            id="tableTitle"
            component="div"
          >
            {t("board.casesHistory.date")}
          </Typography>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={caseDate}
            onChange={(event) => setCaseDate(event.target.value)}
          />
        </TableCell>
        <TableCell class="col-md-4" style={{ paddingLeft: '30px' }}>
          <Typography
            variant="h7"
            id="tableTitle"
            component="div"
          >
            {t("board.casesHistory.description")}
          </Typography>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            value={caseDescription}
            onChange={(event) => setCaseDescription(event.target.value)}
          />
        </TableCell>
      </TableRow>

    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TableCases() {
  const { cases, setFilteredCases, filtrarArray,
     setLayers, filterCase ,setFilteredCasesMenu,
     filteredCasesMenu, setCases, layers} = useContext(UserContext);

  const rows = [...cases];
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [modalMessages, setModalMessages] = React.useState(false);
  const [idCase, setIdCase] = React.useState(null)
  const [checkDisable, setCheckDisable] = React.useState(false)

  const [t, i18next] = useTranslation("global")

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);

    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if(newSelected.length >= 15){
      setCheckDisable(true)}
      else{
        setCheckDisable(false)
      }


    let newCase = filtrarArray(rows, newSelected, '_id');
    setFilteredCases(newCase);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function formatDate(mongoDateString) {
    const date = new Date(mongoDateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year.toString()}`;
    return formattedDate;
  }

  return (
    <>
 <ModalDialogComponent
        view = {modalMessages}
        tittle ={t("board.deleteCase.confirm")}
        message={t("board.deleteCase.deleteDescription")}
        buttons={[
            {value:t("board.deleteCase.accept"),onclick:()=>{
              let jsonCase = {active : false}

              editCase({jsonCase, idCase:idCase }).then((response)=>{
                if (response.status === 200) {
                   // handleClose();
                    //props.setServerResponse(response.status);
                    let temporal = filteredCasesMenu.filter(ele =>ele._id !==idCase)
                    setFilteredCasesMenu(temporal)
                    setCases(cases.filter(ele=> ele._id !==idCase))
                    setModalMessages(false);
                    if(layers._id && layers._id == idCase)
                    setSelected([])
                    setLayers({})
                }
            }).catch(error => {
              errors.addErroLog(
                {
                  data: {
                    url: window.location,
                    error: error.toString(),
                    message: error.message,
                    file: error.file,
                    line: error.line
                  }
                }).then();
                console.log(error);
            });
                 }}
        ]}
        />
      <EnhancedTableToolbar numSelected={selected.length} />
      <TableContainer sx={{ maxHeight: "calc(100vh - 300px)" }}>
        <Table
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          stickyHeader
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>

            {rows && stableSort(rows, getComparator(order, orderBy))
              .filter((row) => !((!row.caseResult[0].caseName.toUpperCase().includes(filterCase.caseName.toUpperCase()))
                || !formatDate(row.creationDate).toUpperCase().includes(filterCase.caseDate.toUpperCase())
                || (filterCase.caseDescription && row.caseResult[0].caseDescription && row.caseResult[0].caseDescription.toUpperCase().includes(filterCase.caseDescription.toUpperCase())))
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover

                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(event) => handleClick(event, row._id)}
                        disabled={checkDisable && !selected.includes(row._id) ? true : false }
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.caseResult[0].caseName}
                    </TableCell>
                    <TableCell
                      align="center"
                    >
                      {row.creationDate === null ? '' : formatDate(row.creationDate)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{maxWidth: '50%'}}
                    >
                      {row.caseResult[0].caseDescription && `${row.caseResult[0].caseDescription.substr(0, 40 )}...`}
                    </TableCell>
                    <TableCell
                      align="center"
                    > <IconButton
                    aria-label="close"
                    onClick={()=>{
                      setModalMessages(true);
                      setIdCase (row._id)
                    }}

                >
                    <DeleteIcon />
                </IconButton>

                    </TableCell>

                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>

      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          style={{position: "relative"}}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("board.casesHistory.rowsPerPage")}
            >

      </TablePagination>
      {checkDisable &&
        <div style={{display: "flex", alignItems: "center", position: "absolute", bottom: "45px"}}>
            <img src={infoIcon} style={{height: "1.57rem"}}/>
            <p style={{color: "red", fontSize: "0.86rem", paddingLeft: "0.5rem"}}>{t("board.casesHistory.maximumCases")}</p>
        </div>
      }

    </>
  );
}