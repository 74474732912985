import Header from "../layouts/header";
import Title from "../components/title";
import Footer from "../components/footer";
import TutorialsList from "../components/tutorials/tutorials-list";
import { useTranslation } from "react-i18next";



export default function Tutorials(props) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    // let { state } = useLocation();
    // const plan = state.plan;

    return (

        <>
            <Header {...props}/>
            <Title title={t(`tutorials.title`)} subtitle={t(`tutorials.subTitle`)}/>
            <TutorialsList />
            <Footer />

        </>

    )
}