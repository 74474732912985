import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PushPinIcon from '@mui/icons-material/PushPin';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import { Link } from "react-router-dom";

function FlexibilityComponent(props) {
    const { layers } = props;
    let smallestObject =layers && layers.length > 0 ? layers.reduce((prev, current) => (prev.flexibility < current.flexibility) ? prev : current) : {flexibility: 0 }  ;
    
    function retunrColor(flexibility) {
        let color = '';
        if (flexibility >= 80) {
            color = 'rgb(0, 153, 255)';
        } else if (flexibility >= 40 && flexibility < 80) {
            color = 'rgb(245, 169, 5)';
        } else {
            color = 'rgb(210, 119, 73)';
        }
        return color;
    }

    return (
        <div
            style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: 'pointer'
            }}
        >
            {
                layers && layers.length > 0 && 
                <>
                <div style={{
                    background: retunrColor(parseFloat(smallestObject.flexibility.toFixed(1))),
                    borderRadius: "50%",
                    width: 15,
                    height: 15,
                    marginRight: 10
                }}
                ></div>
                <b style={{fontSize: 15}}>
                    
                    {smallestObject.flexibility.toFixed(1)}
                </b>
                </>
            }
           

        </div>
    )
}

export default function CaseList(props) {
    const {
        createCaseComparison,
        filteredCasesMenu,
        filteredCases,
        addLayers,
        newArrayNames,
        setNewArrayNemes,
        pinState,
        indexCaseOne,
        indexCaseTwo,
        startComparison,
        listState, setListState,
    } = useContext(UserContext);

    const [open, setOpen] = React.useState(true);
    const casosFiltrados = filteredCases;
    

    const handleClick = () => {
        setOpen(!open);
    };

    function createArrayNames(name) {
        if (newArrayNames.includes(name)) {
            setNewArrayNemes(newArrayNames.filter(id => id !== name));
        } else {
            setNewArrayNemes([...newArrayNames, name]);
        }
    }


    return (<>
       
        <List
            sx={{ width: '100%', bgcolor: 'background.paper', padding: 0, height: "55vh", overflow: "auto" }}
            aria-labelledby="nested-list-subheader"
        >
            
            {
                filteredCasesMenu && filteredCasesMenu.map((caseInfo, index) => (
             
                        <Link to={`/board/1`} style={{
                            display: 'flex', textDecoration: 'none',
                            color:  indexCaseOne._id ===  caseInfo._id || listState[caseInfo._id] ||  indexCaseTwo._id  === caseInfo._id ? 'white' :'#495461' ,
                        }}>
                            <ListItemButton style={{ padding: 0 }} onClick={() => 
                                
                                 addLayers(caseInfo._id, listState[caseInfo._id] ? true : false )}>
                                <Grid container spacing={0} key={caseInfo._id} style={{
                                    backgroundColor: indexCaseOne._id ===  caseInfo._id || listState[caseInfo._id] ? '#495461' : indexCaseTwo._id  === caseInfo._id ? '#8292a5': 'white',
                                    borderTop: "0", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", borderRight: "1px solid rgba(0, 0, 0, 0.12)"
                                }}>

                                    <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <ListItemIcon onClick={() =>  createCaseComparison(caseInfo._id)} style={{
                                            color: listState[caseInfo._id] ? 'white' : '#495461' ,
                                            display: listState[caseInfo._id] ? 'flex' : 'none', minWidth: 35
                                        }}>

                                            <PushPinIcon style={{ transform: indexCaseOne._id ===  caseInfo._id?   '' : 'rotate(90deg)' }} />

                                        </ListItemIcon>

                                    </Grid>


                                    <Grid item xs={8} style={{ borderRight: listState[index] || indexCaseTwo === index  ? '1px solid white' : '1px solid rgba(0, 0, 0, 0.12)', maxWidth: '58.5%' }} >

                                        <p style={{ marginTop: 10, marginBottom: 10, fontSize: 11, paddingLeft: '-5px' }}>
                                            {caseInfo.caseResult[0].caseName.length > 28 ? 
                                            `${caseInfo.caseResult[0].caseName.substr(0, 28)}...` : caseInfo.caseResult[0].caseName}
                                        </p>

                                    </Grid>

                                    <Grid item xs={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                        <FlexibilityComponent layers={caseInfo.caseResult[0].layers} />
                                    </Grid>


                                </Grid>

                            </ListItemButton>
                        </Link>

                      
                ))
            }

        </List></>
    );
}