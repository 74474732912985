import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from "react-i18next";
import { exchangeToken } from '../../actions/users'
import jwt_decode from "jwt-decode";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserProfile(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    let token = localStorage.getItem("saxToken")

    const infoUser = token ? jwt_decode(token) : null;


    const [openAlert, setOpenAlert] = useState(false);

    const handleCloseAlert = (event, reason) => {

        setOpenAlert(false);
    };


    const copyToken = async() => {
        let token = localStorage.getItem("saxToken")
        navigator.clipboard.writeText(token)
        setAnchorEl(null);
        setOpenAlert(true)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [t, i18next] = useTranslation("global")

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                <Chip avatar={<Avatar>{props && props.infoUser ?  props.infoUser.name[0].toUpperCase(): 'U'}</Avatar>} label={props && props.infoUser ?  props.infoUser.name: 'Usuario'} onClick={handleClick} />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        }
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}



            >

                <SimpleTreeView>
                    <TreeItem itemId="download" label={
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: ".5rem", fontSize: "14px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368" style={{margin: "0.5rem"}}>
                            <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/>
                        </svg>
                            {t("board.downLoad")}
                        </div>
                    }>
                        <TreeItem itemId="power-factory" onClick={()=> {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                              event: 'button_click',
                              button_id:'download-pf',
                            });
                            window.open( `${process.env.REACT_APP_BACKEND_URL}/api/download-demo/PowerFactory`)
                            setAnchorEl(null)
                        }}
                        label={
                                    <p style={{marginTop: "0.4rem", marginBottom: "0.4rem", fontSize: "14px"}}>Power Factory</p>
                                } />
                        <TreeItem itemId="psse" onClick={()=>{
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                              event: 'button_click',
                              button_id:'download-psse',
                            });
                            window.open( `${process.env.REACT_APP_BACKEND_URL}/api/download-demo/PSSE`)
                            setAnchorEl(null)
                        }}
                        label={
                                    <p style={{marginTop: "0.4rem", marginBottom: "0.4rem", fontSize: "14px"}}>PSSE v35</p>
                                } />
                    </TreeItem>
                    { ((infoUser && infoUser.developer) || (infoUser && infoUser.userType === 4)) &&
                        <TreeItem itemId="developer" label={
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: "1.5rem", fontSize: "14px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368" style={{margin: "0.5rem"}} >
                            <path d="M344-296 160-480l184-184 56 58-126 126 126 126-56 58Zm-144 16h80v40h400v-40h80v160q0 33-23.5 56.5T680-40H280q-33 0-56.5-23.5T200-120v-160Zm80-400h-80v-160q0-33 23.5-56.5T280-920h400q33 0 56.5 23.5T760-840v160h-80v-40H280v40Zm0 520v40h400v-40H280Zm0-640h400v-40H280v40Zm336 504-56-58 126-126-126-126 56-58 184 184-184 184ZM280-800v-40 40Zm0 640v40-40Z"/>
                        </svg>
                            {t("board.developer.developer")}
                        </div>
                    }>
                        <TreeItem itemId="developer-token" label={
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: "1.5rem", fontSize: "14px"}}
                                    onClick={() => {
                                        window.dataLayer = window.dataLayer || [];
                                        window.dataLayer.push({
                                        event: 'button_click',
                                        button_id:'developer-token',
                                        });
                                        copyToken()
                                    }}>
                                        <p style={{marginTop: "0.4rem", marginBottom: "0.4rem", fontSize: "14px"}}>{t("board.token")}</p>
                                    </div>
                                }
                             />
                        <TreeItem itemId="developer-api" label={
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: "1.5rem", fontSize: "14px"}}
                                    onClick={()=>{
                                        window.dataLayer = window.dataLayer || [];
                                        window.dataLayer.push({
                                        event: 'button_click',
                                        button_id:'python-code',
                                        });
                                        window.open( `${process.env.REACT_APP_BACKEND_URL}/api/download-demo/PythonCode`)
                                        setAnchorEl(null)
                                    }}>
                                            {t("board.developer.pythonCode")}
                                        </div>

                                }
                             />
                        </TreeItem>
                    }
                </SimpleTreeView>

                <MenuItem>

                <a style={{fontSize: "14px"}} onClick={()=>{ localStorage.removeItem("saxToken"); window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/logout`}}>
                {//; return href={``}
                }
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                    </a>

                </MenuItem>

            </Menu>
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{vertical: 'top', horizontal: 'right'} }
            >
                <Alert onClose={handleCloseAlert} severity={"success"} sx={{ width: '100%' }}>
                    {t("board.copy")}
                </Alert>
            </Snackbar>
        </>
    );
}