import * as React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, FormControl, FormHelperText, Grid, OutlinedInput, Snackbar, Stack } from '@mui/material';
import { playgroundValidate } from '../../actions/users';
import { errors } from '../../config/errors';
import { validateCodeConsult } from '../../actions/validationCode';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;


  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalValidateCode({ open, data, onClose, onResolve }) {
  const [loading, setLoading] = React.useState(false);
  const [errorData, setErrorData] = React.useState({});
  const [messageAlert, setMessageAlert] = React.useState('');
  const [openAlert, setOpenAlert] = React.useState(false);
  const [dataSend, setDataSend] = React.useState(data)

  const handleSubmit = async (event) => {
    event.preventDefault();
    let validator = [];
    let internalErrorData = {}

    setErrorData(internalErrorData)

    if (validator.length === 0) {

      try {

        setLoading(true);
        validateCodeConsult(dataSend).then(response => {

          if (response.status === 200) {
            onResolve && onResolve();
            onClose();
          }
          else {
            setOpenAlert(true);
            setMessageAlert(`Codigo Incorrecto; Porfavor verifiquelo.`);
          }
        })

      } catch (error) {
        errors.addErroLog({
          data: {
            url: window.location,
            error: error.toString(),
            status: error.statusText,
            message: error.message,
            file: error.file,
            line: error.line
          }
        }).then()
        console.log(error);
      }
    }
  };

  const handleChange = (name) => (event) => {
    setDataSend({ ...data, [name]: event && event.target ? event.target.value : event });
    setErrorData({ ...errorData, [name]: '' });
  };

  const handleClose = () => {
    setLoading(false);
    setOpenAlert(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <BootstrapDialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          TransitionComponent={Transition}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
            Solicitud exitosa
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Tu código de confirmación fue enviado por medio de whatsapp y correo electrónico. Por favor, verifícalo.
            </Typography>
            <Grid item xs={12} container justifyContent="center">
              <FormControl sx={{ m: 1 }} variant="outlined" size="small">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <FormHelperText id="code">Código de confirmación <b>*</b></FormHelperText>
                  {errorData.code && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '0.75rem',
                        fontWeight: 400,
                        lineHeight: 1.66,
                        marginTop: '4px',
                      }}
                    >
                      {errorData.code}
                    </span>
                  )}
                </div>

                <OutlinedInput
                  id="code"
                  type='number'
                  aria-describedby="names"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  value={data.code}
                  onChange={handleChange('code')}
                  error={errorData.code ? true : false}
                />
              </FormControl>
            </Grid>

            {loading && <LinearProgress color="secondary" />}
          </DialogContent>

          <DialogActions>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Stack spacing={2} sx={{ width: '100%', zIndex: 25000 }}>
                <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {messageAlert}
                  </Alert>
                </Snackbar>
              </Stack>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                style={{
                  background: '#7220db',
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: 16,
                  marginTop: 7,
                  marginBottom: 7,
                  fontWeight: 700,
                }}
              >
                Validar
              </Button>
            </div>
          </DialogActions>
        </BootstrapDialog>
      </form>
    </div>
  );
}
