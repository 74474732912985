import Header from "../layouts/header";
import Title from "../components/title";
import RegisterForm from "../components/register/register-form";
import Footer from "../components/footer";

import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";
import { useTranslation } from "react-i18next";

function Register(props) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'
    return (
        <ContextPlansAndServicesProvider>
            <Header {...props}/>
            <Title title={t(`demo.demoRegistrationRequired`)} />
            <RegisterForm />
            <Footer />
        </ContextPlansAndServicesProvider>
    );
}

export default Register;