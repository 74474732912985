import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  FormControl,
  FormHelperText,
  Card,
  OutlinedInput,
  Grid,
  Container,
  Checkbox,
  CircularProgress,
  Button
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import 'react-international-phone/style.css';
import ModalTreatmentPersonalData from '../register/modal-treatment-personal-data';
import ModalTermsAndConditions from '../register/modal-terms-and-conditions';
import { generateCodeContact, sendMessageContact } from '../../actions/contact'
import ModalDialogComponent from '../../components/dialog/dialog-component'
import { RepeatOneSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { errors } from '../../config/errors';
import { generateCode } from '../../actions/validationCode';
import ModalValidateCode from '../modal-validation/modal-validation';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    errors.addErroLog(
      {
        data: {
          url: window.location,
          error: error.toString(),
          message: error.message,
          file: error.file,
          line: error.line
        }
      }).then();
    return false;
  }
};

const CustomButton = styled(Button)({
  background: '#7220db',
  transition: 'box-shadow .3s ease-in-out',
  marginTop: 10,
  color: '#fff',
  borderRadius: 2,
  border: 'none',
  fontWeight: 700,
  padding: '10px 60px',
  cursor: 'pointer',
  fontSize: 16,
  textTransform: 'none',

});

function ContactForm(props) {
  const [t, i18next] = useTranslation("global")
  const language = localStorage.getItem('i18nextLng') || 'es'

  const [checked, setChecked] = React.useState(true);
  const [errorChecked, setErrorChecked] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [data, setData] = React.useState({});
  const [resolveHandler, setResolveHandler] = React.useState(null)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t(`contact.contactFirstNameRequired`)),
    lastName: Yup.string().required(t(`contact.contactLastNameRequired`)),
    email: Yup.string().required(t(`contact.contactEmailRequired`)),
    company: Yup.string().required(t(`contact.contactCompanyRequired`)),
    nit: Yup.string(),
    message: Yup.string().required(t(`contact.contactMessageFieldRequired`)),
    phone: Yup.string().required(t(`contact.contactPrefixRequired`)).test('phone', t(`contact.contactPhoneRequired`), (value) => {
      return !(/^\+[\d\s]+$/).test(value) || isNaN(value);
    }),
    agreeTerms: Yup.boolean().oneOf([true], t(`contact.required`)),
    //.required('Celular requerido'),
  });


  const formik = useFormik({
    initialValues: {
      name: props.infoUser && props.infoUser.name || '',
      lastName: props.infoUser && props.infoUser.lastName || '',
      email: props.infoUser && props.infoUser.email || '',
      phone: props.infoUser && props.infoUser.phone || '',
      company: props.infoUser && props.infoUser.typeAccount || '',
      nit: props.infoUser && props.infoUser.typeAccountId || '',
      message: '',
      agreeTerms: false,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (loading) return;
      setLoading(true);
      if (!checked) return setErrorChecked(true)
      if (errorChecked) return setErrorChecked(false)
      setData(values)

      generateCodeContact(values).then(async (response) => {
        if (response.status === 200) {
          const handleSendMessage = async () => {
            try {
              const sendResponse = await sendMessageContact(values);
              const { status, error } = sendResponse;

              if (status === 200) {
                setModalOpen(true);
                setMessage({
                  tittle: t(`contact.message`),
                  message: t(`contact.decriptionMessage`),
                });
                resetForm();
                setChecked(true);
              } else {
                setModalOpen(true);
                setMessage({
                  tittle: 'Mensaje de Error',
                  message: error || '',
                });
                errors.addErroLog({
                  data: {
                    url: window.location.href,
                    error,
                    message: error,
                    file: '',
                    line: '',
                  },
                });
              }
            } catch (err) {
              console.error('Error al enviar mensaje:', err);
            } finally {
              setLoading(false);
            }
          };

          if (response.response !== "email registered") {
            setOpenModal(true);
            await new Promise((resolve) => setResolveHandler(() => resolve));
          }

          await handleSendMessage();
        }
      });

    }
  });

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (<>
    <ModalDialogComponent
      view={modalOpen}
      tittle={message.tittle && message.tittle || ''}
      message={message.message && message.message || ''}
      buttons={[
        {
          value: t(`contact.accept`), onclick: () => {
            setModalOpen(false)
            setMessage({})
          }
        }
      ]}
    />

    <ModalValidateCode
      open={openModal}
      data={data}
      onClose={() => handleCloseModal()}
      onResolve={resolveHandler}
    />

    <Container style={{ maxWidth: '60%', marginBottom: 20 }}>
      {/* Primer Grid para Nombres y Apellidos */}
      <Card style={{ paddingTop: 40 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <FormControl sx={{ m: 1, }} style={{ minWidth: '90%', }} variant="outlined" size="small" >
              <div
                style={{
                  display: 'flex',
                }}>
                <FormHelperText id="email">{t(`contact.contactFirstName`)}<b>*</b></FormHelperText>
                {formik.errors.name && formik.touched.name && formik.errors.name &&
                  <span
                    style={{
                      color: 'red',
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      lineHeight: 1.66,
                      marginTop: '4px'
                    }}>
                    {formik.errors.name}
                  </span>
                }
              </div>
              <div>
                <OutlinedInput
                  id='name'
                  size="small"
                  disabled={props.infoUser}
                  placeholder='Ej: Carlos'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  style={{
                    minWidth: '80%',
                    fontSize: '0.75rem',
                    //fontWeight: 400,
                    //height: '50px'

                  }}
                  error={formik.errors.name && formik.touched.name}

                />
              </div>
            </FormControl>
          </Grid>

          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <FormControl sx={{ m: 1 }} style={{ minWidth: '90%', }} variant="outlined" size="small">
              <div
                style={{
                  display: 'flex',
                }}>
                <FormHelperText id="email">{t(`contact.contactLastName`)}<b>*</b></FormHelperText>
                {formik.errors.lastName && formik.touched.lastName && formik.errors.lastName &&
                  <span
                    style={{
                      color: 'red',
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      lineHeight: 1.66,
                      marginTop: '4px'
                    }}>
                    {formik.errors.lastName}
                  </span>
                }
              </div>
              <div>

                <OutlinedInput
                  id='lastName'
                  placeholder='Ej: Smith'
                  size="small"
                  disabled={props.infoUser}
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  style={{
                    minWidth: '80%',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                  }}
                  error={formik.errors.lastName && formik.touched.lastName}

                />
              </div>
            </FormControl>
          </Grid>

          {/* Segundo Grid para Correo Electrónico y Celular */}
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <FormControl sx={{ m: 1 }} style={{ minWidth: '90%', }} variant="outlined" size="small">
              <div
                style={{
                  display: 'flex',
                }}>
                <FormHelperText id="email">{t(`contact.contactEmail`)}<b>*</b></FormHelperText>

                {formik.errors.email && formik.touched.email && formik.errors.email &&
                  <span
                    style={{
                      color: 'red',
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      lineHeight: 1.66,
                      marginTop: '4px'
                    }}>
                    {formik.errors.email}
                  </span>
                }
              </div>
              <div>
                <OutlinedInput
                  id='email'
                  size="small"
                  disabled={props.infoUser}
                  placeholder='Ej: carlos@gmail.com'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  style={{
                    minWidth: '80%',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                  }}
                  error={formik.errors.email && formik.touched.email}

                />
              </div>
            </FormControl>
          </Grid>

          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <FormControl sx={{ m: 1 }} style={{ minWidth: '90%', }} variant="outlined" size="small">
              <div
                style={{
                  display: 'flex',
                }}>
                <FormHelperText id="email">{t(`contact.contactPhone`)}<b>*</b></FormHelperText>
                {formik.errors.phone && formik.touched.phone && formik.errors.phone &&
                  <span
                    style={{
                      color: 'red',
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      lineHeight: 1.66,
                      marginTop: '4px'
                    }}>
                    {formik.errors.phone}
                  </span>
                }
              </div>
              <div>
                <PhoneInput
                  id='phone'
                  size="small"
                  disabled={props.infoUser}
                  defaultCountry='co'
                  countryEditable={false}
                  value={formik.values.phone}
                  onChange={formik.handleChange("phone")}
                  style={{
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    marginLeft: 35,
                    marginRight: 0
                  }}
                  error={formik.errors.phone && formik.touched.phone}

                />
              </div>
            </FormControl>
          </Grid>

          {/* Tercer Grid para Empresa y Nit */}
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <FormControl sx={{ m: 1 }} style={{ minWidth: '90%', }} variant="outlined" size="small">
              <div
                style={{
                  display: 'flex',
                }}>
                <FormHelperText id="email">{t(`contact.contactCompany`)}<b>*</b></FormHelperText>

                {formik.errors.company && formik.touched.company && formik.errors.company &&
                  <span
                    style={{
                      color: 'red',
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      lineHeight: 1.66,
                      marginTop: '4px'
                    }}>
                    {formik.errors.company}
                  </span>
                }
              </div>
              <div>
                <OutlinedInput
                  id='company'
                  size="small"
                  disabled={props.infoUser}
                  placeholder='Ej: Industrias eléctricas S.A.S'
                  onChange={formik.handleChange}
                  value={formik.values.company}
                  style={{
                    minWidth: '80%',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                  }}
                  error={formik.errors.company && formik.touched.company}

                />
              </div>
            </FormControl>
          </Grid>

          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <FormControl sx={{ m: 1 }} style={{ minWidth: '90%' }} variant="outlined" size="small">
              <div
                style={{
                  display: 'flex',
                }}>
                <FormHelperText id="email">{t(`contact.contactTaxId`)}</FormHelperText>

                {formik.errors.nit && formik.touched.nit && formik.errors.nit &&
                  <span
                    style={{
                      color: 'red',
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      lineHeight: 1.66,
                      marginTop: '4px'
                    }}>
                    {formik.errors.nit}
                  </span>
                }
              </div>
              <div>
                <OutlinedInput
                  id='nit'
                  size="small"
                  disabled={props.infoUser}
                  placeholder='Ej: 123456789-0'
                  onChange={formik.handleChange}
                  value={formik.values.nit}
                  style={{
                    minWidth: '80%',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                  }}
                  error={formik.errors.nit && formik.touched.nit}

                />
              </div>
            </FormControl>
          </Grid>

          {/* Cuarto Grid para Mensaje */}
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <FormControl sx={{ m: 1, }} style={{ minWidth: '90%' }} variant="outlined" size="small">
              <div
                style={{
                  display: 'flex',
                }}>
                <FormHelperText id="email">{t(`contact.contactMessageField`)}<b>*</b></FormHelperText>
                {formik.errors.message && formik.touched.message && formik.errors.message &&
                  <span
                    style={{
                      color: 'red',
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      lineHeight: 1.66,
                      marginTop: '4px'
                    }}>
                    {formik.errors.message}
                  </span>
                }
              </div>
              <div>
                <OutlinedInput
                  id='message'
                  placeholder={t(`contact.contactPlaceHolderMessage`)}

                  multiline={true}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  style={{

                    fontSize: '0.75rem',
                    fontWeight: 400,
                  }}
                  rows={8}
                  sx={{ width: '100%' }}
                  error={formik.errors.message && formik.touched.message}
                  helperText={formik.errors.message && formik.touched.message && formik.errors.message}
                />
              </div>
            </FormControl>
          </Grid>

          {/* Quinto Grid para trtamiento */}
          <Grid item xs={12}>
            <div style={{ textAlign: 'center', minWidth: '100%' }}>

              <div
                style={{
                  color: '#868686',
                  fontXeight: 700,
                  fontSize: 14
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox id='agreeTerms'
                      checked={formik.values.agreeTerms}
                      onChange={formik.handleChange} />}

                    label=""
                  />

                  {t(`contact.contactConsentAuthorize`)} <ModalTreatmentPersonalData /> <b>*</b>
                  {`\u00A0`}
                  {formik.touched.agreeTerms && formik.errors.agreeTerms && (
                    <span style={{ color: 'red' }}>{formik.errors.agreeTerms}</span>
                  )}
                </div>

              </div>
            </div>
          </Grid >

          {/* Sexto Grid para boton */}
          <Grid item xs={12}>
            <div style={{
              textAlign: 'end',
              marginRight: 65,

            }}
            >
              <CustomButton
                variant="contained"
                size="large"
                color="secondary"
                type="submit"
                disabled={loading}
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'button_click',
                    button_id: 'request-contact',
                  });
                  formik.handleSubmit();
                }}
                style={{ marginBottom: 40, marginRight: -50 }}

              >
                {loading && <CircularProgress color="inherit" style={{ marginRight: 15, fontSize: 15 }} />}

                {t('contact.request')}
              </CustomButton>
            </div>
          </Grid>
        </Grid>


      </Card>
    </Container>
  </>
  );
}

export default ContactForm;
