import { users } from "../config/users";
export const loginrequest = (language) => {
  return users.loginrequest(language).then(response => response)
};
export const SET_TOKEN = "SET_TOKEN";
const setToken = payload => ({ type: SET_TOKEN, payload });

export const usersConsult = () => {
    return users.usersConsult().then(response => response)
  };
export const exchangeToken = () => {
    return users.exchangeToken().then(response => response)
  };
  export const usersConsultAdmin = () => {
    return users.usersConsultAdmin().then(response => response)
  };

  export const adminGetUsers = () => {
    return users.adminGetUsers().then(response => response)
  };
  export const updateEditUserAdmin = (data) => {
    return users.updateEditUserAdmin(data).then(response => response)
  };

  export const updateAddUserAdmin = (data) => {
    return users.updateAddUserAdmin(data).then(response => response)
  };
  export const updateDeleteUserAdmin = (data) => {
    return users.updateDeleteUserAdmin(data).then(response => response)
  };
  export const getInfoPlan = () => {
    return users.getInfoPlan().then(response => response)
  };
  export const demoRequest = (data) => {
    return users.demoRequest(data).then(response => response)
  };

  export const generateCodePreRegistration = (data) => {
    return users.generateCodeFunction(data).then(response => response)
  };

  export const playgroundRequest = (data) => {
    return users.playgroundRequest(data).then(response => response)
  };

  export const playgroundValidate = (data) => {
    return users.playgroundValidate(data).then(response => response)
  };



  export const validateIp = () => {
    return users.validateIp().then(response => response)
  };

  export const validToken = (data) => {
    return users.validToken(data).then((response) => response);
  };
