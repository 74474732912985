import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { PhoneInput } from 'react-international-phone';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ModalDialogComponent from '../../components/dialog/dialog-component'
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import { adminGetUsers, updateEditUserAdmin } from '../../actions/users'
import { useTranslation } from "react-i18next";
import { CollectionsBookmarkOutlined } from '@mui/icons-material';
import { errors } from '../../config/errors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function ModalEditUser({ user }) {
  const {
    handleClickNoti,
    setTypeOfNotification,
    setNotificationMessage,
    setUsersAdmin,
    sendEdit,
    listUsers,
    setListUsers
  } = useContext(UserContext);

  const createRoles = (user_type) => {
    const ejemplo = ['analista', 'admin', 'developer'];
    let resultado = {}
    ejemplo.map(row => {

      if (row === 'admin' && user_type === 1) {
        resultado[row] = true;
      } else if (row === 'analista' && user_type === 2) {
        resultado[row] = true;
      } else if (row === 'developer' && user_type === 4) {
        resultado[row] = true;
      } else {
        resultado[row] = false;
      }
    });

    if (!Object.values(resultado).some(ele => ele)) resultado['analista'] = true
    return resultado;
  }



  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [lastName, setLasetName] = React.useState('');
  const [lastNameError, setLasetNameError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const [checked, setChecked] = React.useState('');
  const [checkedError, setCheckedError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tittleError, setTittleError] = React.useState('');
  const [messageError, setMessageError] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState({});


  const [t, i18next] = useTranslation("global")

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    setLasetName(user.lastname);
    setPhoneNumber(user.phonenumber)
    setName(user.name);
    setEmail(user.email);
    setChecked(createRoles(user.user_type));
  }, [user])


  const handleClose = () => {
    setOpen(false);
  };

  const updateUserInformation = async (updatedData) => {
    try {
      setLoading(true);
      updateEditUserAdmin({ data: updatedData }).then(response => {
        if (response.status === 200) {
          setLoading(false);
          handleClose();
          setTypeOfNotification('success');
          setNotificationMessage('El usuario se actualizó correctamente');
          handleClickNoti();
          adminGetUsers().then(response => {
            if (response.status === 200) {
              response.response && setListUsers(response.response
                .map(row => ({ ...row, rolName: row.user_type === 1 ? "admin" : row.user_type === 2 ? "analyst" : row.user_type === 4 ? "developer" : "" })))

            }
          })




        }
        setLoading(false);
      })


    } catch (error) {
      errors.addErroLog(
        {
          data: {
            url: window.location,
            error: error.toString(),
            message: error.message,
            file: error.file,
            line: error.line
          }
        }).then();
      console.error('Error al actualizar la información del usuario:', error);
    }
  };


  const handleSubmit = (e) => {

    let sendEdit = true
    e.preventDefault();

    let errors = [];

    if (!name || name === '') {
      errors.push(1);
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (!lastName || lastName === '') {
      errors.push(1);
      setLasetNameError(true);
    } else {
      setLasetNameError(false);
    }

    if (!email || email === '') {
      errors.push(1);
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (!phoneNumber || phoneNumber.length < 4) {
      errors.push(1);
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }

    if ((!checked.admin && !checked.analista && !checked.developer) || Object.values(checked).every(ele => ele)) {
      errors.push(1);
      setCheckedError(true);
    } else {
      setCheckedError(false);
    }



    if (errors.length === 0) {
      let data = {};
      data.id = user.id;
      data.name = name.toLowerCase();
      data.lastname = lastName.toLowerCase();
      data.email = email.toLowerCase();
      data.phone_number = phoneNumber.toLowerCase();
      data.roles = checked;

      setData(data)
      if (data.roles.admin == true && listUsers.filter(ele => ele.rolName == "Administrador").length > 0) {
        sendEdit = false
        setModalOpen(true)
        return
      }
      sendEdit && updateUserInformation(data)
    }

  }
  const handleSendEdit = (data) => {
    updateUserInformation(data)
  }
  const handleGetName = (e) => {
    setName(e.target.value);
  }

  const handleGetLastName = (e) => {
    setLasetName(e.target.value);
  }

  const handleGetEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleGetPhoneNumber = (e) => {
    setPhoneNumber(e);
  }

  const handleGetChecked = (e) => {
    const { name, checked: isChecked } = e.target;
    const names = ['analista', 'admin', 'developer'];

    setChecked((prevState) => {
      let newState = { ...prevState };
      newState[name] = isChecked;
      names.forEach(role => {
        if (role !== name) {
          newState[role] = false;
        }
      });

      return newState;
    });
  }

  return (
    <div>

      <ModalDialogComponent
        view={modalOpen}
        tittle={'Confirmación'}
        message={'Desea perder los permisos de Administrador para otorgarselos a otro usuario, recuerde que al aceptar esta acción debera volver a hacer Log In en la plataforma. '}
        buttons={[
          {
            value: 'Aceptar', onclick: () => {
              setModalOpen(false); updateUserInformation(data)
                .then(() => {
                  localStorage.removeItem('saxToken');
                  window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/logout`
                }
                )
            }
          }
        ]}
      />

      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <BorderColorRoundedIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'xs'}
        TransitionComponent={Transition}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>{t(`board.adminSection.userEdition.editUser`)}</DialogTitle>
          <DialogContent dividers>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  label={t(`board.adminSection.userEdition.name`) + " *"}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={name}
                  onChange={handleGetName}
                  style={{
                    marginBottom: 20,
                  }}
                  size="small"
                  error={nameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  label={t(`board.adminSection.userEdition.lastName`) + " *"}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={lastName}
                  onChange={handleGetLastName}
                  style={{
                    marginBottom: 20,
                  }}
                  size="small"
                  error={lastNameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  label={t(`board.adminSection.userEdition.email`) + " *"}
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={handleGetEmail}
                  size="small"
                  style={{
                    marginBottom: 20,
                  }}
                  error={emailError}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  label={t(`board.adminSection.userEdition.phone`) + " *"}
                  id='phone'
                  size="small"
                  defaultCountry='co'
                  countryEditable={false}
                  value={phoneNumber}
                  onChange={handleGetPhoneNumber}
                  style={{
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    minWidth: '352px'
                  }}
                  error={phoneNumberError}

                />
              </Grid>
            </Grid>
            {/* <TextField
              margin="dense"
              label={t(`board.adminSection.userEdition.phone`)+  " *"}
              type="text"
              fullWidth
              variant="outlined"
              value={phoneNumber}
              onChange={handleGetPhoneNumber}
              size="small"
              error={phoneNumberError}
            /> */}

            <p style={{ marginBottom: 0, color: checkedError && 'red' }}>{t(`board.adminSection.userEdition.roles`) + " *"} </p>
            <FormGroup>

              <FormControlLabel

                control={
                  <Checkbox
                    checked={checked.admin}
                    onChange={handleGetChecked}
                    name="admin"
                  />
                }
                label={t(`board.adminSection.userEdition.admin`)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.analista}
                    onChange={handleGetChecked}
                    name="analista"
                  />
                }
                label={t(`board.adminSection.userEdition.analyst`)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.developer}
                    onChange={handleGetChecked}
                    name="developer"
                  />
                }
                label={t(`board.adminSection.userEdition.developer`)}
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t(`board.adminSection.userEdition.cancel`)}</Button>
            <Button type='submit'>{t(`board.adminSection.userEdition.edit`)}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}