import { useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ModalFlexibilityRanges() {

    /**
     * Translation hook call
     */
    const [t, i18next] = useTranslation("global")

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div>
                <Tooltip title={t("board.details")} onClick={handleClickOpen}>
                    <IconButton>
                        <InfoOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <b>{t("board.flexibilityScale")}</b>
                    </BootstrapDialogTitle>
                    <DialogContent dividers >
                        <Grid container >
                            <Grid item xs={3} >
                                <Grid container style={{ borderRight: "1px solid grey", }}>
                                    <Grid item xs={12} style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                        <div style={{
                                            background: '#d27749',
                                            borderRadius: "50%",
                                            width: 20,
                                            height: 20,
                                            marginRight: 5
                                        }}
                                        ></div>
                                        <span><b>{t("board.critical")}</b></span>
                                    </Grid>

                                    <Grid item xs={12} style={{ display: "flex", alignItems: "start", justifyContent: "start" }} >

                                        <div style={{
                                            background: '#f5a905',
                                            borderRadius: "50%",
                                            width: 20,
                                            height: 20,
                                            marginRight: 5
                                        }}
                                        ></div>
                                        <span><b>{t("board.restrictive")}</b></span>
                                    </Grid>

                                    <Grid item xs={12} style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                        <div style={{
                                            background: '#09f',
                                            borderRadius: "50%",
                                            width: 20,
                                            height: 20,
                                            marginRight: 5
                                        }}
                                        ></div>
                                        <span><b>{t("board.flexible")}</b></span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container style={{ marginLeft: 20 }}>
                                    <Grid item xs={12} style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                        <span><b>{t("board.flexibilityInfo.criticalDescription")}</b></span>
                                    </Grid>

                                    <Grid item xs={12} style={{ display: "flex", alignItems: "start", justifyContent: "start" }} >
                                        <span><b>{t("board.flexibilityInfo.restrictiveDescription")}</b></span>
                                    </Grid>

                                    <Grid item xs={12} style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                        <span><b>{t("board.flexibilityInfo.flexibleDescription")}</b></span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            {t("board.flexibilityInfo.popUpClose")}
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>

        </>

    );
}