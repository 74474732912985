import React, { useContext } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
// import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import {adminGetUsers, updateDeleteUserAdmin} from '../../actions/users'
import { useTranslation } from "react-i18next";
import { errors } from '../../config/errors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function ModalEditUser({ user }) {
  const {
    handleClickNoti,
    setTypeOfNotification,
    setNotificationMessage,
    setUsersAdmin,
     setListUsers
  } = useContext(UserContext);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [t, i18next] = useTranslation("global")

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateUserInformation = async (updatedData) => {
    try {
      setLoading(true);

     updateDeleteUserAdmin({data:updatedData}).then(response =>{
      if (response.status === 200) {

        adminGetUsers().then(response => {
          response && response.response && setListUsers(response.response
            .map(row=> ({...row, rolName: row.user_type === 1 ? "admin": row.user_type === 2 ?  "analyst" : row.user_type === 4 ? "developer" : ""  })))

      })

        setLoading(false);
        handleClose();
        setTypeOfNotification('success');
        setNotificationMessage('El usuario se eliminó correctamente');
        handleClickNoti();

      }
     })


    } catch (error) {
      errors.addErroLog(
        {
          data: {
            url: window.location,
            error: error.toString(),
            message: error.message,
            file: error.file,
            line: error.line
          }
        }).then();
      console.error('Error al actualizar la información del usuario:', error);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    data.id = user.id;
    updateUserInformation(data);

  }

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'xs'}
        TransitionComponent={Transition}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>{t("board.adminSection.deleteUser.deleteUser")}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              {t("board.adminSection.deleteUser.deleteDescription")}
            </DialogContentText>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("board.adminSection.deleteUser.cancel")}</Button>
            <Button type='submit'>{t("board.adminSection.deleteUser.delete")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}