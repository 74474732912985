import Header from "../layouts/header";
import Title from "../components/title";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";

export default function Error404(props) {
    const [t, i18next] = useTranslation("global");
    const language = localStorage.getItem('i18nextLng') || 'es';

    return (
        <>
            <Header {...props} />
            <Title title={t(`error404.title`)} />
            <div className="error-message">
                <h2>{t(`error404.message`)}</h2>
                <p>{t(`error404.description`)}</p>
            </div>
            <Footer />
        </>
    );
}
