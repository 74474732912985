import React, { useContext } from 'react';
import { UserContext } from '../contexts/context-layout-user';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {

    const {
        openNoti,
        handleCloseNoti,
        typeNotification,
        notificationMessage,
    } = useContext(UserContext);

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={openNoti} autoHideDuration={4000} onClose={handleCloseNoti}>
                <Alert
                    onClose={handleCloseNoti}
                    severity={typeNotification}
                    sx={{
                        width: '100%'
                    }}
                >
                    {notificationMessage}
                </Alert>
            </Snackbar>

        </Stack>
    );
}