import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ImageDemo from '../../assets/demo-unlock-img.png';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function TryApp() {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    return (
        <Box style={{
            marginTop: '50px',
            textAlign: 'left',
            background: 'linear-gradient(90deg,#3f008e,#7220db 39.39%,#ff6a13 100.59%)',
            position: 'relative',
        }}
        >
            <Grid
                container
                spacing={0}
                style={{
                    paddingTop: 20,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: 1170
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={7}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                        paddingBottom: 20,

                    }}
                >
                    <Typography
                        sx={{ pl: { xs: 2, md: 0 } }}
                        style={{
                            fontWeight: 700,
                            color: '#fff',
                            fontSize: 32,
                            marginBottom: 0,
                            marginTop: 0

                        }}
                    >
                        {t(`home.text.tryApp`)}
                    </Typography >
                    <Typography
                        sx={{ pl: { xs: 2, md: 0 }, mb: {xs: 3, md: 0} }}
                        style={{
                            fontWeight: 400,
                            color: '#fff',
                            fontSize: 16
                        }}
                    >
                        {t(`home.text.tryAppDescription`)}
                    </Typography>
                    <Link to={'/solicitardemo'} style={{ textDecoration: 'none' }}>
                        <Button
                            data-gtm-id='request-demo'
                            sx={{ml: { xs: 2, md: 0 }}}
                            style={{
                                background: '#ffff',
                                color: '#4b4b4a',
                                fontWeight: 700,
                                textTransform: 'none'
                            }}
                        >
                            {t(`home.text.RequestDemo`)}
                        </Button>
                    </Link>
                </Grid>
                <Grid
                    item
                    xs={5}
                    sx={{display: {xs: 'none', md: 'block'}}}
                >
                    <img
                        src={ImageDemo}
                        alt="image demo"
                        style={{
                            position: 'absolute',
                            width: 270,
                            marginLeft: 50,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}