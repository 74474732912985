
import {validateIp} from '../actions/users'

 function getToken() {
    const params = window.location.href.split('?')[1];
    let token = params.split('&')[0]
    const dateCase =  params.split('case=')[1]
    token = token.split('=')[1] 
    token &&  localStorage.setItem("saxToken",token)
    localStorage.setItem("control", true);
    localStorage.getItem("saxToken") && validateIp().then(response =>
     { 
             if (response.status === 1) {
                
                window.location.href =  token  ? dateCase ? `/board/1?case=${dateCase}`:  `/board`: '/'
            }
            else {
                // localStorage.removeItem("saxToken");
                // window.location.href = '/' 
            }
        })
   

return
}
  
export default getToken;



