import { useRouteError} from "react-router-dom";
import { useState, useEffect, useContext, createContext } from 'react';
import {administrationErrorsDetection} from '../actions/errors'
import  ModalDialogComponent from '../components/dialog/dialog-component'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
export default function ErrorPage(props) {

  const error = useRouteError();
  const [modalMessages, setModalMessages] = useState(true);

  administrationErrorsDetection({data :{url:window.location, error: error.toString(), status:error.statusText, message: error.message, file: error.file , line: error.line}}).then()
  // setTimeout(() =>{
  //   window.location.href = localStorage.getItem("saxToken") ? "/board": "/"
  // },7000)

  return (
    <div id="error-page">
      <ModalDialogComponent
        view = {modalMessages}
        tittle ={'Error encontrado'}
        message={'Se ha encontrado un error en el proceso que estabas ejecutando. Hemos notificado al administrador y trabajaremos lo antes posible para solucionar el problema. Aun así, puede notificar el error en el apartado de contacto de la plataforma.'}
        buttons={[
            {value:'Aceptar',onclick:()=>{setModalMessages(false); window.location.href = localStorage.getItem("saxToken") ? "/board": "/"}}
        ]}
        />
        {/* <IconButton
          sx={{
            position: 'absolute',
            right: 30,
            top: 30,
            color: (theme) => theme.palette.grey[500],
        }}>  */}
          <ErrorOutlineIcon sx={{ fontSize: 80 }}/>
          {/* </IconButton>
        */}
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {/* <i>{error.statusText || error.message || "Access Denied"}</i>
        <i>{error.file  }</i>: <i>{ error.line }</i> */}
      </p>
    </div>
  );
}