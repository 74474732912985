import { GET, POST } from "./api.js";
export const users = {

    usersConsult: (data) => GET(`/user`, data),
    exchangeToken: () => GET(`/user/exchangeToken`),
    usersConsultAdmin: (data) => GET(`/user/useradmin`, data),
    demoRequest: (data) => POST(`/pre-registration`, data),
    playgroundRequest: (data) => POST(`/playground-registration/send-code`, data),
    playgroundValidate: (data) => POST(`/playground-registration/validate`, data),
    getInfoPlan: () => GET(`/user/getPlan`),
    loginrequest: (language) => GET(`/login?language=${language}`),
    validateIp: () => GET(`/user/validateIp`),
    validToken: (data) => POST(`/user/validToken`, data),
    adminGetUsers:()=> GET(`/user/admin/getUsers`),
    updateEditUserAdmin:(data)=>POST(`/user/admin/editUsers`,data),
    updateDeleteUserAdmin:(data)=>POST(`/user/admin/deleteUsers`,data),
    updateAddUserAdmin:(data)=>POST(`/user/admin/addUsers`,data),
    generateCodeFunction: (data) => POST(`/pre-registration/generate-code`, data),
}