import { plans } from "../config/plans";


export const plansConsult = () => {
    return plans.plansConsult().then(response => response)
  };
  export const homePlans = () => {
    return plans.homePlans().then(response => response)
  };
  export const consultCompanies = () => {
    return plans.consultCompanies().then(response => response)
  };
