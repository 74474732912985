import { createStore, applyMiddleware, compose } from 'redux';
//import { createLogger } from 'redux-logger';
import reducers from './reducers';
import thunk from "redux-thunk";

//const logger = createLogger();

export function configureStore(initialState) {

    //const middlewares = [logger];
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    
    const store = createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(thunk))
        //composeEnhancers(applyMiddleware(...middlewares))
    );

    return store;
}
