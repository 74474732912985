import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from "react-i18next";

import { UserContext } from '../../contexts/context-layout-user';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ForwardIcon from '@mui/icons-material/Forward';

const columns = [
    {
        id: 'name',
        label: 'generators',
        minWidth: 90
    },
    {
        id: 'code',
        label: 'action',
        minWidth: 100
    },
    {
        id: 'population',
        label: 'mitigatePower',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'mitigateSensitivity',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'mitigateEffectiveness',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];


function getNameGroupGenerator(nameGroup) {
    return nameGroup.replace(/\?/g, ', ').trim();
}




function TableImprovementActions(props) {
    const rows = props.information;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [t, i18next] = useTranslation("global")

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 437 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>

                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {t(`board.caseInfo.${column.label}`)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows
                            .sort(function(a, b){

                                if(parseFloat(a.efficiency) < parseFloat(b.efficiency)) { return 1; }
                                if(parseFloat(a.efficiency) > parseFloat(b.efficiency)) { return -1; }
                                return 0;
                            })
                            .map((row, index) => {


                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.index}>
                                        {/* {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })} */}
                                        <TableCell align='left'>
                                            {getNameGroupGenerator(row.generatorName)}
                                        </TableCell>
                                        <TableCell align='center' style={{ background: '#ccc' }}>
                                            {row.originalOutput < 0 ? (
                                                <ForwardIcon style={{ transform: 'rotate(270deg)', color: '#099' }} />
                                            ) : row.originalOutput > 0 ? (
                                                <ForwardIcon style={{ transform: 'rotate(90deg)', color: '#ff6' }} />
                                            ) : ('')
                                            }
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.poet}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.output === '1' || row.output === '-1' ? 100 : row.output }
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.output === '1' || row.output === '-1' ? row.poet : row.efficiency }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}


// ---------------------------modal------------------


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ColorButtonCancel = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    // backgroundColor: purple[500],
    backgroundColor: '#3F008E',
    '&:hover': {
        //   backgroundColor: purple[700],
        backgroundColor: '#7220DB',
    },
}));

export default function ModalMitigationActions({ info, caseStudy, index, row }) {
    const [open, setOpen] = React.useState(false);
    const [completeModel, setCompleteModel] = React.useState([]);
    const [t, i18next] = useTranslation("global")
    const { pin } = React.useContext(UserContext);

    function createCompleteModel() {
        let models = row.model;

        let listGenerators = caseStudy.linealDescription[0].pes[2].values;
        let listInitMonitored = caseStudy.linealDescription[0].sensitivities[2].data;
        let allModels = [];


        function hasMoreThanTwoDecimals(number) {
            return /\.\d{3,}/.test(number);
        }

        models.map(model => {
            const name = getNameGroupGenerator(model.generatorName)
            const arrayNames = name && name.split(',');
            const arraySinEspaciosVacios = arrayNames.filter(elemento => elemento.trim() !== '' && elemento.trim() !== 'RHS');


            let newModel = {
                generatorName: "",
                originalOutput: "",
                output: "",
                poet: "",
                efficiency: "",
            }

            if (arraySinEspaciosVacios.length === 1) {

                let indexGenerator = listGenerators.findIndex(elem => elem.toString().trim() === arrayNames[0].toString().trim())
                let poetGenerator = listInitMonitored[indexGenerator][1] * listInitMonitored[indexGenerator][2];

                newModel.generatorName = model.generatorName;
                newModel.originalOutput = model.output;
                newModel.output = hasMoreThanTwoDecimals(model.output) ?
                    Number((Math.abs(model.output * 100)).toFixed(2)) :
                    model.output
                newModel.poet = Number(Math.abs(poetGenerator).toFixed(2));

                newModel.efficiency = ((Math.abs(newModel.poet * newModel.output))/100).toFixed(2);
                allModels.push(newModel)
                setCompleteModel(allModels);

            } else if (arraySinEspaciosVacios.length > 1) {
                let sumPoet = 0;

                arraySinEspaciosVacios.map(name => {

                    let indexGenerator = listGenerators.findIndex(elem => elem.trim() === name.trim());

                  if (indexGenerator<0) return

                    let poetGenerator = listInitMonitored[indexGenerator][1] * listInitMonitored[indexGenerator][2];
                    sumPoet += poetGenerator;
                })

                newModel.generatorName = model.generatorName;
                newModel.originalOutput = model.output;
                newModel.output = hasMoreThanTwoDecimals(model.output) ?
                    Number((Math.abs(model.output * 100)).toFixed(2)) :
                    model.output

                newModel.poet = Number(Math.abs(sumPoet).toFixed(2));

                newModel.efficiency = ((Math.abs(newModel.poet * newModel.output))/100).toFixed(2);
                allModels.push(newModel)
                setCompleteModel(allModels);

            }
        });

        setCompleteModel(allModels);
    }

    const handleClickOpen = () => {
        setOpen(true);
        createCompleteModel();
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div >
            <Typography variant="subtitle2" onClick={() => {handleClickOpen()}} style={{ cursor: 'pointer' }}>
                {info}
            </Typography>
            <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    maxWidth= "md"
    PaperProps={{
        style: {
            height: (pin) || (row.model.length >= 0 && row.model.length <= 6) ? '' : "100%",
            maxHeight: pin || row.model.length > 0 ? "585px" : "100%"
        },
    }}
>
    <DialogTitle style={{padding: "9px 24px"}}>{t("board.caseInfo.mitigateTitle")}</DialogTitle>
    <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', background: '#e2e0e0', padding: "10px 24px"}}>
        <div style={{display: "flex", justifyContent: "space-evenly"}}>
            <div style={{fontSize: "0.875rem"}}>
                <b>{t("board.caseInfo.contingency")}:</b> {row.contingency}
            </div>
            <div style={{fontSize: "0.875rem"}}>
                <b>{t("board.caseInfo.element")}:</b> {row.monitored}
            </div>
        </div>
    </DialogContent>
    <DialogContent dividers style={{ padding: 0 }}>
        <TableImprovementActions information={completeModel} />
    </DialogContent>
    <DialogActions>
        <ColorButtonCancel onClick={handleClose} startIcon={<HighlightOffIcon />}>{t("board.caseInfo.popUpClose")}</ColorButtonCancel>
    </DialogActions>
</Dialog>
        </div>
    );
}