
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { consultCompanies } from '../../actions/plans';
import ModalDialogComponent from '../../components/dialog/dialog-component'
import * as React from 'react';
import { PlansAndServicesContext } from '../../contexts/contex-plans-and-services';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import MenuItem from '@mui/material/MenuItem';
import { errors as errorsFront} from '../../config/errors';

import { playgroundRequest } from '../../actions/users.js'
import ModalRegistration from './modal-registration';
import ModalTermsAndConditions from './modal-terms-and-conditions';
import ModalTreatmentPersonalData from './modal-treatment-personal-data';
const CustomButton = styled(Button)({
    background: '#7220db',
    transition: 'box-shadow .3s ease-in-out',
    marginTop: 10,
    color: '#fff',
    borderRadius: 2,
    border: 'none',
    fontWeight: 700,
    padding: '10px 60px',
    cursor: 'pointer',
    fontSize: 16,
    textTransform: 'none',
});
const listApp = [{ value: 'PowerFactory' }, { value: 'PSSE' }, { value: 'Ambos' }]
function getSmallDateTime() {
    const now = new Date();
    return now.toISOString();
}


function RegisterForm() {
    const { setOpenAlert, setMessageAlert, setOpenLoader } = React.useContext(PlansAndServicesContext);
    const [values, setValues] = React.useState({
        names: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        company: '',
        checkOne: false,
        checkTwo: false,
    });
    const [errors, setErrors] = React.useState({
        names: { error: false, label: '' },
        lastName: { error: false, label: '' },
        email: { error: false, label: '' },
        phoneNumber: { error: false, label: '' },
        company: { error: false, label: '' },
        checkOne: { error: false, label: '' },
        checkTwo: { error: false, label: '' },
    });

    const [tittleError, setTittleError] = React.useState('');
    const [messageError, setMessageError] = React.useState('');
    const [modalDialogComponentLoad, setModalDialogComponentLoad] = React.useState(false);
    const [data, setData] = React.useState({});
    const [errorData, setErrorData] = React.useState({});
    const [errorNit, setErrorNit] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [errorChecked, setErrorChecked] = React.useState('');
    const [checkedTwo, setCheckedTwo] = React.useState(false);
    const [errorCheckedTwo, setErrorCheckedTwo] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [companies, setCompanies] = React.useState([]);
    const [stateSaxDiagramsUrl, setStateSaxDiagramsUrl] = React.useState(false);
    const [url, setUrl] = React.useState("https://appf-saxdiagrams-dev-01.azurewebsites.net/"); // URL de la página que deseas mostrar

    React.useEffect(() => {
        consultCompanies().then((response) => {
            if (response.status === 200) setCompanies(response.response)
        })
    }, [])
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\+\d{11}$/;

    const handleAction = () => {
        setOpenModal(true);
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        let domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let validator = [];
        let dataResponse = {};
        let info = ['names', 'email', 'phone', "company", "lastname", 'platform']
        let internalErrorData = {}
        Object.keys(data).length === 0 && validator.push(1);
        info.forEach(key => {
            if (!data[key]) {

                internalErrorData[key] = "requerido"

                validator.push(1);
            } else {

                if (key === 'email') {
                    const emailParts = data[key].split('@');
                    if (emailParts.length !== 2 || !domainRegex.test(emailParts[1])) {
                        internalErrorData[key] = "dominio invalido";
                        validator.push(1);
                    } else if (!emailRegex.test(data[key])) {
                        internalErrorData[key] = "formato invalido";
                        validator.push(1);
                    }
                }
                if (key === 'phone' && data[key].length < 10) { internalErrorData[key] = "invalido"; validator.push(1); }
                dataResponse[key] = data[key];
            }
        })
        setErrorData(internalErrorData)


        if (!checked) {
            setErrorChecked("requerido");
            validator.push(1);
        } else {
            dataResponse.terms_and_conditions = checked;
        }

        if (!checkedTwo) {
            setErrorCheckedTwo("requerido");
            validator.push(1);

        } else {
            dataResponse.treatment_personal_data = checkedTwo;
        }

        if (validator.length === 0) {
            dataResponse.date = getSmallDateTime();
            dataResponse.status = 1;
            if (errorData.length > 0) return

            try {
                setLoading(true);
                playgroundRequest(dataResponse).then(response => {

                    if (response.status === 200 && response.response === "email registered") {
                        setLoading(false);
                        setStateSaxDiagramsUrl(true);
                        return
                    }

                    if (response.status === 200) {
                        setOpenLoader(false);
                        handleAction();
                        setLoading(false);
                        setChecked(false);
                        setCheckedTwo(false);
                    }
                    else {
                        setMessageError(`Porfavor verifique la información\n o pongase en contacto con el Administrador\ncodigo de error: ${response.error}`)
                        setTittleError('Problema en solicitud')
                        setModalDialogComponentLoad(true)
                        setTimeout(() => {
                            setLoading(false);
                            setModalDialogComponentLoad(false)
                        }, 5000)
                    }
                })

            } catch (error) {
                errorsFront.addErroLog(
                    {
                      data: {
                        url: window.location,
                        error: error.toString(),
                        message: error.message,
                        file: error.file,
                        line: error.line
                      }
                    }).then();
                console.log(error);
            }
        } else {
            setMessageAlert("Por favor, complete todos los campos marcados con un asterisco (*)");
            setOpenAlert(true);
        }
    };

    function validateFields() {
        const newErrors = {};
        const hasErrors = [];

        for (const field in values) {
            if (field === 'phoneNumber') {
                const phoneNumberRegex = /\+57\s*\d+/;
                const isValidPhoneNumber = phoneNumberRegex.test(values[field]);

                if (values[field] === '') {
                    newErrors[field] = { error: true, label: 'Requerido' };
                    hasErrors.push(true);
                } else if (!isValidPhoneNumber) {
                    newErrors[field] = { error: true, label: 'Formato de número incorrecto' };
                    hasErrors.push(true);
                } else {
                    newErrors[field] = { error: false, label: '' };
                }
            } else if (field === 'email') {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+/;
                const isValidEmail = emailRegex.test(values[field]);

                if (!isValidEmail) {
                    newErrors[field] = { error: true, label: 'Formato de correo incorrecto' };
                    hasErrors.push(true);
                } else {
                    newErrors[field] = { error: false, label: '' };
                }
            } else if (field === 'checkOne' || field === 'checkTwo') {
                if (values[field] === false) {
                    newErrors[field] = { error: true, label: 'Requerido' };
                    hasErrors.push(true);
                } else {
                    newErrors[field] = { error: false, label: '' };
                }
            } else if (field !== 'nit') {
                if (values[field] === '') {
                    newErrors[field] = { error: true, label: 'Requerido' };
                    hasErrors.push(true);
                } else {
                    newErrors[field] = { error: false, label: '' };
                }
            }
        }

        if (values.email === '') {
            newErrors.email = { error: true, label: 'Requerido' };
            hasErrors.push(true);
        }

        setErrors(newErrors);

        return hasErrors;
    }


    const handleChange = (name) => (event) => {

        setData({ ...data, [name]: event && event.target ? event.target.value : event });
        setErrorData({ ...errorData, [name]: '' });
    };

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);

        setErrorChecked("");
    };

    const handleCheckboxChangeTwo = (event) => {
        setCheckedTwo(event.target.checked);

        setErrorCheckedTwo("");
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setData({ ...data, code: '' });
    }

    return (<>
        <ModalDialogComponent
            view={modalDialogComponentLoad}
            tittle={tittleError}
            message={messageError}
            buttons={[
                // {value:'Aceptar', onclick:`setClose(true)`}
            ]}
        />
        <Container
            fixed
            style={{
                marginBottom: '40px'
            }}
        >
            <Grid
                container
                spacing={0}
            >
                {stateSaxDiagramsUrl ? (
                    <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h3>¡Esta es la zona de juegos de Sax o Playgroud!</h3>
                        <p>Aquí te mostramos de forma gráfica todos los análisis que
                            puedes hacer desde la información que genera SAX de tu red bajo análisis.</p>
                        <div style={{
                            height: "100vh",
                            width: "100vw",
                            position: "relative",
                            overflow: "hidden" // Asegúrate de ocultar las áreas recortadas
                        }}>
                            <iframe
                                src={url}
                                style={{
                                    border: "none",
                                    height: "150%", // Aumenta el tamaño del iframe para que se haga más grande
                                    width: "150%",  // Escalar el contenido
                                    position: "absolute",
                                    top: "-25%", // Ajusta la posición para centrar la vista
                                    left: "-25%",
                                    transform: "scale(0.9)", // Aplica un zoom adicional
                                    transformOrigin: "center", // Define el origen del zoom
                                }}
                                title="Iframe Example"
                            />
                        </div>
                    </Grid>

                ) : (
                    <>
                        <Grid
                            item
                            xs={6}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}

                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    width: '100%'
                                }}
                            >
                                <InfoOutlinedIcon
                                    style={{
                                        paddingRight: 15,
                                        fontSize: 40,
                                        color: '#7220db'
                                    }}
                                />
                                <h2>¡Ten en cuenta!</h2>
                            </div>

                            <div
                                style={{
                                    paddingRight: 55,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div

                                    style={{
                                        marginLeft: 50
                                    }}
                                >
                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: 16,
                                            color: '#868686',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Antes de iniciar la descarga del demo, es importante que sigas estas instrucciones:
                                    </p>

                                    <ul style={{
                                        margin: 0,
                                        fontSize: 16,
                                        color: '#868686',
                                        textAlign: 'left',

                                    }}>
                                        <li>Asegúrate de rellenar todos los datos del formulario que tienen un asterisco (*).</li>
                                        <li>Proporciona un correo electrónico válido para recibir información importante acerca del demo.</li>
                                        <li>Ingresa información verdadera y precisa para poder acceder al demo.</li>
                                    </ul>


                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: 16,
                                            color: '#868686',
                                            textAlign: 'left',

                                        }}
                                    >
                                        Si aún no has completado el formulario, por favor hazlo antes de hacer clic en el botón 'Registrarme'."
                                    </p>
                                </div>
                            </div>

                            <ModalRegistration setData={setData} open={openModal} data={data} onClose={() => handleCloseModal()} />

                        </Grid>

                        <Grid
                            item
                            xs={6}
                            style={{
                                borderLeft: '1px solid #e7e7e7'
                            }}
                        >
                            <form onSubmit={handleSubmit}>
                                <h2
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        color: '#282828',
                                        marginTop: 20,
                                        marginBottom: 10

                                    }}
                                >
                                    SAX Playground
                                </h2>

                                <Grid
                                    container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Grid item sx={6} >
                                        <FormControl sx={{ m: 1 }} variant="outlined" size="small">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                }}>
                                                <FormHelperText id="names">Nombres <b>*</b></FormHelperText>
                                                {errorData.names &&
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '0.75rem',
                                                            fontWeight: 400,
                                                            lineHeight: 1.66,
                                                            marginTop: '4px'
                                                        }}>
                                                        {errorData.names}
                                                    </span>
                                                }
                                            </div>

                                            <OutlinedInput
                                                id=""
                                                aria-describedby="names"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}

                                                value={data.names}
                                                onChange={handleChange('names')}
                                                error={errorData.names ? true : false}
                                            />

                                        </FormControl>
                                    </Grid>
                                    <Grid item sx={6}>
                                        <FormControl sx={{ m: 1 }} variant="outlined" size="small">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                }}>
                                                <FormHelperText id="lastname">Apellidos <b>*</b></FormHelperText>
                                                {errorData.lastname &&
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '0.75rem',
                                                            fontWeight: 400,
                                                            lineHeight: 1.66,
                                                            marginTop: '4px'
                                                        }}>
                                                        {errorData.lastname}
                                                    </span>
                                                }
                                            </div>
                                            <OutlinedInput
                                                id=""
                                                aria-describedby="lastname"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                                value={data.lastname}
                                                onChange={handleChange('lastname')}
                                                error={errorData.lastname ? true : false}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Grid item sx={6} style={{
                                        paddingRight: 15
                                    }}>
                                        <FormControl sx={{ m: 1 }} variant="outlined" size="small" >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                }}>
                                                <FormHelperText id="email">Correo electrónico <b>*</b></FormHelperText>
                                                {errorData.email &&
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '0.75rem',
                                                            fontWeight: 400,
                                                            lineHeight: 1.66,
                                                            marginTop: '4px'
                                                        }}>
                                                        {errorData.email}
                                                    </span>
                                                }
                                            </div>
                                            <OutlinedInput
                                                id=""
                                                aria-describedby="email"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                                value={data.email}
                                                onChange={handleChange('email')}
                                                error={errorData.email ? true : false}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <div >
                                        <div
                                            style={{
                                                display: 'flex',
                                            }}>
                                            <FormHelperText id="phone">Celular <b>*</b></FormHelperText>
                                            {errorData.phone &&
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '0.75rem',
                                                        fontWeight: 400,
                                                        lineHeight: 1.66,
                                                        marginTop: '3px',
                                                        marginLeft: '10px'
                                                    }}>
                                                    {errorData.phone}
                                                </span>

                                            }
                                        </div>
                                        <PhoneInput
                                            defaultCountry="co"
                                            value={data.phone}
                                            onChange={handleChange('phone')}
                                            style={{ width: '214px' }}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Grid item sx={6} style={{
                                        paddingRight: 15
                                    }}>
                                        <FormControl sx={{ m: 1 }} variant="outlined" size="small" style={{ minWidth: 210 }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                }}>
                                                <FormHelperText id="company">Empresa <b>*</b></FormHelperText>
                                                {errorData.company &&
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '0.75rem',
                                                            fontWeight: 400,
                                                            lineHeight: 1.66,
                                                            marginTop: '3px',
                                                            marginLeft: '10px'
                                                        }}>
                                                        {errorData.company}
                                                    </span>

                                                }
                                            </div>

                                            <input style={{ borderRadius: '4px', height: '35px', borderWidth: '1px', fontSize: '16px', borderColor: errorData.company ? 'rgba(255,0,0,0.5)' : 'rgba(0,0,0,0.3)', width: '100%' }}
                                                value={data.company}
                                                list="datalistOptions"
                                                onChange={handleChange('company')} />
                                            <datalist id="datalistOptions">
                                                {companies && data.company && data.company !== '' && companies.filter((ele => ele.toUpperCase().includes(data.company.toUpperCase()))).map((ele) => (<option value={ele} />))}


                                            </datalist>
                                            {/* <OutlinedInput
                           id=""
                           aria-describedby="company"
                           inputProps={{
                               'aria-label': 'weight',
                           }}
                           list={["Opción 1", "Opción 2", "Opción 3"]}
                           value={data.company}
                           onChange={handleChange('company')}
                           error={errorData.company ? true : false}
                       /> */}

                                        </FormControl>

                                    </Grid>
                                    <Grid item sx={6} style={{
                                        paddingRight: 0
                                    }}>
                                        <FormControl sx={{ m: 1 }} variant="outlined" size="small" style={{ padding: 0, minWidth: 200 }} >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                }}>
                                                <FormHelperText id="lastname">Aplicación <b>*</b></FormHelperText>
                                                {errorData.platform &&
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '0.75rem',
                                                            fontWeight: 400,
                                                            lineHeight: 1.66,
                                                            marginTop: '3px',
                                                            marginLeft: '10px'
                                                        }}>
                                                        {errorData.platform}
                                                    </span>

                                                }
                                            </div>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                size="small"
                                                //label="Select"
                                                defaultValue="EUR"
                                                //helperText="Please select your currency"
                                                style={{ padding: 0, width: "100%", }}
                                                onChange={handleChange('platform')}
                                                error={errorData.platform ? true : false}
                                            >
                                                {/* <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    //value={age}
                                    style={{width:100}}
                                    label="Demo"
                                    onChange={()=>{}}
                                > */}

                                                {listApp.map((option) => (
                                                    <MenuItem value={option.value}>{option.value}</MenuItem>

                                                ))}
                                                {/* </Select> */}
                                            </TextField>

                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <div>

                                    <FormControl sx={{ m: 1, width: 447, display: 'none' }} variant="outlined" size="small" >
                                        <div
                                            style={{
                                                display: 'flex',

                                            }}>
                                            <FormHelperText
                                                id="requirement"
                                            >
                                                Tipo de solicitud
                                            </FormHelperText>
                                            {errorNit &&
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '0.75rem',
                                                        fontWeight: 400,
                                                        lineHeight: 1.66,
                                                        marginTop: '3px',
                                                        marginLeft: '10px',

                                                    }}>
                                                    {errorNit}
                                                </span>

                                            }
                                        </div>
                                        <TextField
                                            hiddenLabel
                                            id="filled-hidden-label-small"
                                            value={data.requirement}
                                            size="small"
                                            onChange={handleChange('requirement')}
                                            disabled
                                        />
                                    </FormControl>
                                </div>

                                <div style={{ textAlign: 'center' }}>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'start',
                                            marginLeft: 60
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: '#868686',
                                                fontXeight: 700,
                                                fontSize: 14
                                            }}
                                        >

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',

                                                }}
                                            >
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label=""
                                                    checked={checked}
                                                    onChange={handleCheckboxChange}

                                                />
                                                Acepto los <ModalTermsAndConditions /> <b>*</b>{`\u00A0`}
                                                {errorChecked &&
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errorChecked}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                color: '#868686',
                                                fontXeight: 700,
                                                fontSize: 14
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    checked={checkedTwo}
                                                    onChange={handleCheckboxChangeTwo}
                                                    label=""
                                                />

                                                Autorizo y acepto el <ModalTreatmentPersonalData /> <b>*</b>
                                                {`\u00A0`}
                                                {errorCheckedTwo &&
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errorCheckedTwo}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        textAlign: 'end',
                                        marginRight: 65,

                                    }}
                                    >
                                        <CustomButton
                                            variant="contained"
                                            size="large"
                                            color="secondary"
                                            type="submit"
                                        >
                                            {loading && <CircularProgress color="inherit" style={{ marginRight: 15, fontSize: 15 }} />}

                                            Solicitar
                                        </CustomButton>
                                    </div>
                                </div>
                            </form>
                        </Grid>
                    </>
                )}


            </Grid >
        </Container >
    </>
    )
}

export default RegisterForm;