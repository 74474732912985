import React, { useContext } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Slide from '@mui/material/Slide';
import { useTranslation } from "react-i18next";
import {
  redirect,
  useNavigate,
  Navigate
} from "react-router-dom";


import TableCases from './table-cases';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalCases() {
  const navigate = useNavigate()
  const { } = useContext(UserContext);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate(`/admin`);
  };

  const [t, i18next] = useTranslation("global")

  return (
    <div style={{ display: "inline-block" }}>

      <IconButton aria-label="fingerprint" color="primary" onClick={handleClickOpen} >
        <LibraryBooksIcon style={{ color: '#939494' }} />
      </IconButton>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth='md'
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
         {t("board.casesHistory.casesHistory")}
        </BootstrapDialogTitle>
        <div style={{marginLeft: '18px', marginTop:'-20px', fontSize : '12px'}}> <h7>Seleccione los casos a cargar</h7></div>
        <DialogContent dividers sx={{ overflow: 'hidden' }}>

          <TableCases />

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t("board.casesHistory.popUpUpload")}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}