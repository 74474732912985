import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const CardHover = styled(Card)({
    boxShadow: '0 2px 5px rgba(0,0,0,.3)',
    '&:hover': {
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.3)',
        transition: 'box-shadow 0.3s ease-in-out',
    },
});

const plan = {
    creation_date: '',
    payment_date: '',
    start_date: '',
    end_date: '',
    time_plan: 1,
    status: '',
    plan_value: 1090,
    total_user: 1,
    is_demo: '',
    features: [
        { feature_id: 5, name: 'Usuario', name_english: 'UserUser', quantity: 1, unite_price: 1090, }
    ],
};

function Plans(props) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    const newPlans = [
        {
            name: t('home.plans.plan.basic.title'),
            creation_date: '',
            payment_date: '',
            start_date: '',
            end_date: '',
            time_plan: 3,
            status: '',
            plan_value: 9484,
            total_user: 5,
            is_demo: '',
            features: [
                { feature_id: 5, name: 'Usuario', name_english: 'User', quantity: 5, unite_price: 9108 },
                { feature_id: 5, name: 'Excel + Sensibilidad de generadores', name_english: 'Excel + Generator Sensitivity', quantity: 1, unite_price: 188 },
                { feature_id: 5, name: 'Históricos', name_english: 'Historical data', quantity: 1, unite_price: 94 },
                { feature_id: 5, name: 'Archivos de despacho', name_english: 'Dispatch files', quantity: 1, unite_price: 94 }
            ],
        },
        {
            name: t('home.plans.plan.standard.title'),
            creation_date: '',
            payment_date: '',
            start_date: '',
            end_date: '',
            time_plan: 6,
            status: '',
            plan_value: 15087,
            total_user: 5,
            is_demo: '',
            features: [
                { feature_id: 5, name: 'Usuario', name_english: 'User', quantity: 5, unite_price: 14711 },
                { feature_id: 5, name: 'Excel + Sensibilidad de generadores', name_english: 'Excel + Generator Sensitivity', quantity: 1, unite_price: 188 },
                { feature_id: 5, name: 'Históricos', name_english: 'Historical data', quantity: 1, unite_price: 94 },
                { feature_id: 5, name: 'Archivos de despacho', name_english: 'Dispatch files', quantity: 1, unite_price: 94 }
            ],
        },
        {
            name: t('home.plans.plan.Professional.title'),
            creation_date: '',
            payment_date: '',
            start_date: '',
            end_date: '',
            time_plan: 12,
            status: '',
            plan_value: 22794,
            total_user: 5,
            is_demo: '',
            features: [
                { feature_id: 5, name: 'Usuario', name_english: 'User', quantity: 5, unite_price: 22418 },
                { feature_id: 5, name: 'Excel + Sensibilidad de generadores', name_english: 'Excel + Generator Sensitivity', quantity: 1, unite_price: 188 },
                { feature_id: 5, name: 'Históricos', name_english: 'Historical data', quantity: 1, unite_price: 94 },
                { feature_id: 5, name: 'Archivos de despacho', name_english: 'Dispatch files', quantity: 1, unite_price: 94 }
            ],
        }
    ]


    return (
        <div style={{ background: '#fafafa' }}>
            <Box style={{ paddingTop: '50px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '70px', maxWidth: 1170 }}>
                <Typography style={{ fontSize: 32, fontWeight: 700, color: '#282828' }} sx={{ ml: { xs: 1, md: 0 } }}>
                    {t(`home.plans.title`)}
                </Typography>
                <Typography style={{ marginBottom: 50, fontSize: 16, position: 'relative' }}>
                    <span>
                        {t(`home.plans.subTitle`)}
                    </span>

                    <Link to={'/planesyservicios'} state={{ plan }} style={{ color: '#f07d18', marginLeft: 5, fontWeight: 700, textDecoration: 'none', cursor: 'pointer', zIndex: 100 }}>
                        {t(`home.plans.subTitleLink`)}
                    </Link>
                </Typography>
                <Grid container spacing={{ xs: 5, md: 12 }} sx={{ pl: { xs: 2, md: 0 }, pr: { xs: 2, md: 0 } }}>
                    {
                        newPlans.map((plan, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <CardHover variant="outlined">
                                    <CardContent>
                                        <Typography variant="h3" style={{ fontSize: 18, fontWeight: 400, color: '#4b4b4a', marginTop: 20, marginBottom: 10 }}>
                                            {plan.name}
                                        </Typography>
                                        <div style={{ borderBottom: '1px solid #f07d18', marginBottom: 15 }}> </div>
                                        <Typography component="div" style={{ fontSize: 24, marginBottom: 10 }}>
                                            <span style={{ fontWeight: 400, fontSize: 18, marginRight: 5, color: '#aeaeae' }}>
                                                {t(`home.plans.currency`)}
                                            </span>

                                            <span style={{ fontWeight: 700 }}>
                                                {`$${Number(plan.plan_value).toLocaleString('en-US')}`}
                                            </span>
                                        </Typography>

                                        <Typography sx={{ mb: 1.5, color: '#f07d18', fontWeight: 700, fontSize: 16 }}>
                                            {t(`home.plans.include`)}
                                        </Typography>

                                        <Typography variant="body2" style={{ fontSize: 14, lineHeight: 2.3 }}>
                                            {`${plan.total_user} ${t('home.plans.users')}`}
                                            <br />
                                        </Typography>

                                        <Typography variant="body2" style={{ fontSize: 14, lineHeight: 2.3 }}>
                                            {`${t('home.plans.access')} ${plan.time_plan} ${t('home.plans.months')}`}
                                            <br />
                                        </Typography>

                                        {
                                            plan.features && plan.features.map((feature, index) => (
                                                <Typography key={index} variant="body2" style={{ fontSize: 14, lineHeight: 2.3, display: (feature.name === 'Usuario' || feature.name === 'User') ? 'none' : 'block' }}>
                                                    {language === 'en' ? feature.name_english : feature.name}
                                                    <br />
                                                </Typography>
                                            ))
                                        }
                                    </CardContent>
                                    <CardActions style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <Link to={'/planesyservicios'} state={{ plan }}>
                                            <Button
                                                data-gtm-id='get-a-quote'
                                                variant='outlined'
                                                style={{
                                                    textTransform: 'none', background: 'rgba(240,125,24,.1)', color: '#f07d18',
                                                    border: '1px solid #f07d18', fontWeight: 700, marginBottom: 10,
                                                }}
                                            >
                                                {t(`home.plans.getAQuote`)}
                                            </Button>
                                        </Link>
                                    </CardActions>
                                </CardHover>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </div>
    );
}

export default Plans;