
import { contactRoute } from "../config/contact";


export const sendMessageContact = (data) => {
  return contactRoute.sendMessageContact(data).then(response => response)
};

export const generateCodeContact = (data) => {
  return contactRoute.generateCodeFunction(data).then(response => response)
};